import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import colors from '../../../Utils/colors';
import images from '../../../Utils/images';
import CheerioButton from '../../../Components/CheerioButton';
import ResponseModal from './ResponseModal';
import ConditionModal from './ConditionModal';
import TimeDelayModal from './TimeDelayModal';
import SendEmailModal from './SendEmailModal';
import InvokeNewFlowModal from './InvokeNewFlowModal';
import SendWATemplateModal from './SendWATemplateModal';
import EndFlowModal from './EndFlowModal';
import IncominWAModal from './IncominWAModal';
import SendShopifyTemplateModal from './SendShopifyTemplateModal';
import FacebookLeadNodeModal from './FacebookLeadNodeModal';
import { saveFbToken, getAllAdAccounts, verifyAttributes } from '../../../Services';
import { Tooltip } from '@mui/material';
import CampaignSentModal from './CampaignSentModal';
import KylasEventUpdateModal from './KylasEventUpdateModal';
import KylasEventCreate from './KylasEventCreateModal';
import { toast } from 'react-toastify';
import UpdateAttributeModal from './UpdateAttributeModal';
import AttributeConditionModal from './AttributeConditionModal';
import UpdateChatStatusModal from './UpdateChatStatusModal';
import PabblyEventModal from './PabblyEventModal';
import AssignAgentsModal from './AssignAgentsModal';
import GPTNodeModal from './GPTNodeModal';
import WebhookTriggerModal from './WebhookTriggerModal';
import WooCommerceModal from './WooCommerceModal';
import SendWACatalogModal from './SendWACatalogModal';
import WAPaymentModal from './WAPaymentModal';
import PixelEventModal from './PixelEventModal';
import { eventsend } from '../../../Config/analyticsFunctions';
import UpdateShopifyAttributesModal from './UpdateShopifyAttributesModal';
import { savePixelConfiguration } from '../../../Services';
import FormModal from './FormModal';
import AddToLabelModal from './AddToLabelModal';
const EditNodeModal = ({
  setIsEditNodeModalOpen,
  nodeData,
  onSubmit,
  onDelete,
  prevNode,
  oldEdgeDelete,
  duplicateNodeAction,
  shopifyExist,
}) => {
  const [editedData, setEditedData] = useState({ ...nodeData?.data });
  const [before, setBefore] = useState('');
  const [after, setAfter] = useState('');
  const [btndisabled, setBtndisabled] = useState(false);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [showFBWorkflowModal, setShowFBWorkflowModal] = useState(false);
  const [saveConditionText, setSaveConditionText] = useState('Verify Conditions');
  const [conditionError, setConditionError] = useState([]);
  const [verifyStep, setVerifyStep] = useState(true);
  const [adAccounts, setAdAccounts] = useState([]);
  const [saveKeywords, setSaveKeywords] = useState(false);
  const [showPixelEventModal, setShowPixelEventModal] = useState(false);
  const [syncData, setSyncData] = useState({
    accId: '',
    adId: '',
    labelName: '',
    adName: '',
  });
  const localdata = useSelector((state) => state.main.localdata);

  useEffect(() => {
    console.log('editedData', editedData);
    console.log('showFBWorkflowModal', showFBWorkflowModal);
    console.log('before', before);
    console.log('after', after);
  }, [showFBWorkflowModal, editedData, before, after]);

  const verifyAttributeApiCall = () => {
    const data = editedData;
    verifyAttributes(authtoken, data).then((res) => {
      if (res?.flag) {
        setBtndisabled(false);
        setVerifyStep(false);
        setSaveConditionText('Save');
        if (res?.data.length > 0) {
          setConditionError(res?.data);
        }
      } else {
        toast.error(res?.message, { position: 'top-center' });
        setBtndisabled(false);
        setVerifyStep(false);
      }
    });
  };


  const isTokenExpired = (createdAt) => {
    if (!createdAt) return true;
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const daysSinceCreation = (currentDate - createdDate) / (1000 * 60 * 60 * 24);
    return daysSinceCreation >= 60;
  };

  // const getAccountsAPI = (data) => {
  //   getAllAdAccounts(authtoken).then((res) => {
  //     if (res?.flag) {
  //       console.log(res);

  //     } else {
  //       toast.error(res?.message, { position: 'top-center' });
  //     }
  //   });
  // };

  /*   const savePixelConfigurationApiCall = (data) => {
      savePixelConfiguration(authtoken, data).then((res) => {
        if (res?.flag) {
          console.log(res);
          const data_new = {
            key: 'new',
            value: '',
          };
          getAllAdAccounts(authtoken, data_new).then((res) => {
            if (res?.flag) {
              console.log(res);
              setEditedData(res?.data);
            } else {
              toast.error(res?.message, { position: 'top-center' });
            }
          });
        } else {
          toast.error(res?.message, { position: 'top-center' });
        }
      });
    };
   */

  return (
    <>
      <div
        className="DarkBg"
        style={{}}
        onClick={(e) => {
          // setIsEditNodeModalOpen(false);
          e.stopPropagation();
        }}
      >
        <div className="Center">
          <div
            className="d-flex flex-column justify-content-between align-items-center"
            style={{
              width:
                nodeData?.type === 'condition' ||
                  nodeData?.type === 'attributeCondition' ||
                  nodeData?.type === 'sendWATemplate' ||
                  nodeData?.type === 'updateShopifyAttributes'
                  ? 800
                  : nodeData?.type === 'kylasUpdate' || nodeData?.type === 'kylasCreate'
                    ? 700
                    : 510,
              maxHeight: '80vh',
              backgroundColor: colors.white01,
              borderRadius: 16,
              zIndex: 0,
              border: `1px solid ${colors.borderwhite}`,
              paddingBlock: 16,
              paddingInline: 20,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {/* Header */}
            <div
              className="d-flex flex-row justify-content-between align-items-center w-100"
              style={{ paddingBlockEnd: 8, borderBottom: `1px solid ${colors.borderwhite}` }}
            >
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  fontSize: 20,
                  fontWeight: 700,
                  color: colors.black,
                }}
              >
                {nodeData?.type === 'incomingWA'
                  ? 'On Incoming WhatsApp'
                  : nodeData?.type === 'campaign'
                    ? 'Campaign Sent'
                    : nodeData?.type === 'responseMessage'
                      ? 'Response Message'
                      : nodeData?.type === 'chatgpt'
                        ? 'Assign GPT'
                        : nodeData?.type === 'condition'
                          ? 'Set a Condition'
                          : nodeData?.type === 'timeDelay'
                            ? 'Time Delay'
                            : nodeData?.type === 'sendEmail'
                              ? 'Send Email'
                              : nodeData?.type === 'invokeNewFlow'
                                ? 'Invoke New Flow'
                                : nodeData?.type === 'sendWATemplate'
                                  ? 'Send WA Template'
                                  : nodeData?.type === 'catalog'
                                    ? 'Send WA Catalog'
                                    : nodeData?.type === 'whatsAppPayment'
                                      ? 'Payment Message'
                                      : nodeData?.type === 'shopify'
                                        ? 'Shopify Events'
                                        : nodeData?.type === 'facebookLead'
                                          ? 'Facebook Lead'
                                          : nodeData?.type === 'kylasUpdate'
                                            ? 'Kylas Lead Update'
                                            : nodeData?.type === 'kylasCreate'
                                              ? 'Kylas Lead Create'
                                              : nodeData?.type === 'instagramResponseMessage'
                                                ? 'Instagram Response Message'
                                                : nodeData?.type === 'updateAttribute'
                                                  ? 'Update Attribute'
                                                  : nodeData?.type === 'attributeCondition'
                                                    ? 'Attribute Condition'
                                                    : nodeData?.type === 'updateChatStatus'
                                                      ? 'Update Chat Status'
                                                      : nodeData?.type === 'pabblyEvent'
                                                        ? 'Pabbly Event'
                                                        : nodeData?.type === 'endNode'
                                                          ? 'End Flow'
                                                          : nodeData?.type === 'webhookTrigger'
                                                            ? 'Webhook Trigger'
                                                            : nodeData?.type === 'wooWFNode'
                                                              ? 'WooCommerce Event'
                                                              : nodeData?.type === 'formsWFNode'
                                                                ? 'New form response'
                                                                : nodeData?.type === 'assignAgent'
                                                                  ? 'Assign agents'
                                                                  : nodeData?.type === 'updateShopifyAttributes'
                                                                    ? 'Shopify Attribute Update'
                                                                    : nodeData?.type === 'addToLabel'
                                                                      ? 'Add to Label'
                                                                      : nodeData?.type === 'metaPixelNode' && 'Add a Pixel Event'}
              </p>
              <div className="d-flex flex-row justify-content-center align-items-center">
                <Tooltip title={'Duplicate node'}>
                  <img
                    src={images.Copy}
                    style={{
                      height: 24,
                      width: 24,
                      objectFit: 'contain',
                      marginInlineEnd: 8,
                    }}
                    onClick={() => {
                      eventsend('Duplicate_node_workflow_builder_page_clicked');
                      duplicateNodeAction();
                    }}
                  ></img>
                </Tooltip>

                <img
                  src={images.CMClose}
                  style={{ height: 20, width: 20, objectFit: 'contain' }}
                  onClick={() => {
                    setIsEditNodeModalOpen(false);
                  }}
                ></img>
              </div>
            </div>
            {/* ------------------- */}

            {/* Body */}
            <div
              className="w-100 verythinscrollnew"
              style={{ flex: 1, overflowY: 'scroll', paddingInline: 8 }}
            >
              {nodeData?.type === 'incomingWA' && (
                <IncominWAModal
                  data={editedData}
                  setData={setEditedData}
                  saveKeywords={saveKeywords}
                  onSubmit={() => {
                    setSaveKeywords(false);
                    onSubmit(editedData);
                  }}
                  setSaveKeywords={setSaveKeywords}
                />
              )}
              {nodeData?.type === 'campaign' && (
                <CampaignSentModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'responseMessage' && (
                <ResponseModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'instagramResponseMessage' && (
                <ResponseModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'endNode' && (
                <EndFlowModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'condition' && (
                <ConditionModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'timeDelay' && (
                <TimeDelayModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'sendEmail' && (
                <SendEmailModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'invokeNewFlow' && (
                <InvokeNewFlowModal data={editedData} setData={setEditedData} />
              )}
              {nodeData?.type === 'assignAgent' && (
                <AssignAgentsModal data={editedData} setData={setEditedData} />
              )}

              {/* {nodeData?.type === 'pixelEvent' && (
                <PixelEventModal data={editedData} setData={setEditedData} />
              )} */}
              {nodeData?.type === 'chatgpt' && (
                <GPTNodeModal
                  data={editedData}
                  setData={setEditedData}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'sendWATemplate' && (
                <SendWATemplateModal
                  data={editedData}
                  setData={setEditedData}
                  btnDisabled={btndisabled}
                  setBtnDisabled={setBtndisabled}
                  prevNode={prevNode}
                  shopifyExist={shopifyExist}
                />
              )}
              {nodeData?.type === 'catalog' && (
                <SendWACatalogModal
                  data={editedData}
                  setData={setEditedData}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'whatsAppPayment' && (
                <WAPaymentModal
                  data={editedData}
                  setData={setEditedData}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'shopify' && (
                <SendShopifyTemplateModal
                  data={editedData}
                  setData={setEditedData}
                  btnDisabled={btndisabled}
                  setBtnDisabled={setBtndisabled}
                ></SendShopifyTemplateModal>
              )}
              {nodeData?.type === 'facebookLead' && (
                <FacebookLeadNodeModal
                  data={editedData}
                  setData={setEditedData}
                  syncData={syncData}
                  setSyncData={setSyncData}
                  setBtnDisabled={setBtndisabled}
                  setBefore={setBefore}
                  setAfter={setAfter}
                  before={before}
                  after={after}
                  showFBWorkflowModal={showFBWorkflowModal}
                  setShowFBWorkflowModal={setShowFBWorkflowModal}
                  onSubmit={onSubmit}
                ></FacebookLeadNodeModal>
              )}
              {nodeData?.type === 'metaPixelNode' && (
                <PixelEventModal
                  data={editedData}
                  setData={setEditedData}
                  showPixelEventModal={showPixelEventModal}
                  setShowPixelEventModal={setShowPixelEventModal}
                  onSubmit={onSubmit}
                  adAccounts={adAccounts}
                ></PixelEventModal>
              )}
              {nodeData?.type === 'kylasUpdate' && (
                <KylasEventUpdateModal
                  data={editedData}
                  setData={setEditedData}
                  error={conditionError}
                  verifyStep={verifyStep}
                  setVerifyStep={setVerifyStep}
                  setSaveConditionText={setSaveConditionText}
                />
              )}
              {nodeData?.type === 'kylasCreate' && (
                <KylasEventCreate
                  data={editedData}
                  setData={setEditedData}
                  error={conditionError}
                  verifyStep={verifyStep}
                  setVerifyStep={setVerifyStep}
                  setSaveConditionText={setSaveConditionText}
                />
              )}
              {nodeData?.type === 'updateAttribute' && (
                <UpdateAttributeModal
                  data={editedData}
                  setData={setEditedData}
                  prevNode={prevNode}
                />
              )}
              {nodeData?.type === 'addToLabel' && (
                <AddToLabelModal data={editedData} setData={setEditedData} prevNode={prevNode} />
              )}
              {nodeData?.type === 'attributeCondition' && (
                <AttributeConditionModal
                  data={editedData}
                  setData={setEditedData}
                  prevNode={prevNode}
                />
              )}
              {nodeData?.type === 'updateChatStatus' && (
                <UpdateChatStatusModal
                  data={editedData}
                  setData={setEditedData}
                  error={conditionError}
                  verifyStep={verifyStep}
                  setVerifyStep={setVerifyStep}
                  setSaveConditionText={setSaveConditionText}
                />
              )}
              {nodeData?.type === 'pabblyEvent' && (
                <PabblyEventModal
                  data={editedData}
                  setData={setEditedData}
                  error={conditionError}
                  verifyStep={verifyStep}
                  setVerifyStep={setVerifyStep}
                  setSaveConditionText={setSaveConditionText}
                />
              )}
              {nodeData?.type === 'wooWFNode' && (
                <WooCommerceModal
                  data={editedData}
                  setData={setEditedData}
                  btnDisabled={btndisabled}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'formsWFNode' && (
                <FormModal
                  data={editedData}
                  setData={setEditedData}
                  btnDisabled={btndisabled}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'webhookTrigger' && (
                <WebhookTriggerModal
                  data={editedData}
                  setData={setEditedData}
                  prevNode={prevNode}
                  btnDisabled={btndisabled}
                  setBtnDisabled={setBtndisabled}
                />
              )}
              {nodeData?.type === 'updateShopifyAttributes' && (
                <UpdateShopifyAttributesModal
                  data={editedData}
                  setData={setEditedData}
                  prevNode={prevNode}
                />
              )}
            </div>
            {/* ------------------- */}

            {/* Footer */}
            <div
              className="d-flex flex-row justify-content-evenly align-items-center w-100"
              style={{ paddingBlockStart: '10px' }}
            >
              <CheerioButton
                borderStyle={{ width: '45%' }}
                textStyle={{
                  fontSize: 16,
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.white,
                }}
                btnText={
                  nodeData?.type === 'facebookLead'
                    ? 'Sync Facebook Ads'
                    : nodeData?.type === 'metaPixelNode'
                      ? 'Sync Meta Pixels'
                      : nodeData?.type === 'kylasCreate' || nodeData?.type === 'kylasUpdate'
                        ? saveConditionText
                        : 'Save'
                }
                backColor={
                  nodeData?.type === 'facebookLead' || nodeData?.type === 'metaPixelNode'
                    ? '#3366CC'
                    : colors.black
                }
                disabled={btndisabled}
                onclick={(e) => {
                  if (nodeData?.type === 'facebookLead') {
                    if (localdata?.metaConfig?.leadsTokenCreatedAt && !isTokenExpired(localdata?.metaConfig?.leadsTokenCreatedAt)) {
                      const data = {
                        key: 'new',
                        value: '',
                        pixel: "false",
                      };
                      setShowFBWorkflowModal(true);
                      getAllAdAccounts(token, data).then((res) => {
                        if (res?.flag) {
                          setEditedData(res?.data);
                          setBefore(res?.data?.adaccounts?.paging?.cursors?.before);
                          setAfter(res?.data?.adaccounts?.paging?.cursors?.after);
                        } else {
                          toast.error(res?.message, { position: 'top-center' });
                        }
                      });
                    } else {
                      window.FB.login(
                        function (response) {
                          console.log({
                            response,
                          });
                          if (response.authResponse) {
                            let token = authtoken;
                            const fbToken = response.authResponse.accessToken;
                            saveFbToken({ token: fbToken }, token)
                              .then((res) => {
                                if (res?.flag) {
                                  const data = {
                                    key: 'new',
                                    value: '',
                                    pixel: "false",
                                  };
                                  setShowFBWorkflowModal(true);
                                  getAllAdAccounts(token, data).then((res) => {
                                    if (res?.flag) {
                                      setEditedData(res?.data);
                                      setBefore(res?.data?.adaccounts?.paging?.cursors?.before);
                                      setAfter(res?.data?.adaccounts?.paging?.cursors?.after);
                                    } else {
                                      toast.error(res?.message, { position: 'top-center' });
                                    }
                                  });
                                } else {
                                  toast.error(res?.message, { position: 'top-center' });
                                }
                              })
                              .catch((e) => {
                                console.log(e.message);
                              });
                          } else {
                            console.log('User cancelled login or did not fully authorize.');
                          }
                        },
                        {
                          scope:
                            'pages_show_list,ads_management,leads_retrieval,pages_read_engagement',
                        }
                      );
                      e.stopPropagation();
                    }
                  }
                  if (nodeData?.type === 'metaPixelNode') {
                    if (localdata?.metaConfig?.pixelTokenCreatedAt && !isTokenExpired(localdata?.metaConfig?.pixelTokenCreatedAt)) {
                      const dataNew = {
                        key: 'new',
                        value: '',
                        pixel: 'true',
                      };
                      getAllAdAccounts(authtoken, dataNew).then((res) => {
                        if (res?.flag) {
                          console.log("ad acc list", res?.data);
                          setAdAccounts(res?.data?.adaccounts?.data);
                          setShowPixelEventModal(true);
                        } else {
                          toast.error(res?.message, { position: 'top-center' });
                        }
                      });
                    }
                    else {
                      window.FB.login(
                        function (response) {
                          console.log({
                            response,
                          });
                          if (response.authResponse) {
                            const fbToken = response.authResponse.accessToken;
                            const data = {
                              token: fbToken
                            }
                            savePixelConfiguration(authtoken, data).then((res) => {
                              if (res?.flag) {
                                const dataNew = {
                                  key: 'new',
                                  value: '',
                                  pixel: 'true',
                                };
                                getAllAdAccounts(authtoken, dataNew).then((res) => {
                                  if (res?.flag) {
                                    console.log("ad acc list", res?.data);
                                    setAdAccounts(res?.data?.adaccounts?.data);
                                    setShowPixelEventModal(true);
                                  } else {
                                    toast.error(res?.message, { position: 'top-center' });
                                  }
                                });
                              } else {
                                toast.error(res?.message, { position: 'top-center' });
                              }
                            })
                              .catch((e) => {
                                console.log(e.message);
                              });
                          } else {
                            console.log('User cancelled login or did not fully authorize.');
                          }
                        },
                        {
                          scope:
                            'ads_read,ads_management,public_profile,pages_manage_ads,pages_read_engagement,business_management,pages_manage_metadata,pages_show_list,leads_retrieval',
                        }
                      );
                      e.stopPropagation();
                    }
                  }
                  if (nodeData?.type === 'responseMessage') {
                    if (!editedData?.body?.message) {
                      toast.error('body message field is mandatory for a response node', {
                        position: 'top-right',

                      });
                    } else {
                      onSubmit(editedData);
                    }
                  } else if (nodeData?.type === 'kylasCreate' || nodeData?.type === 'kylasUpdate') {
                    console.log('datayaaa', editedData);
                    if (verifyStep) {
                      setBtndisabled(true);
                      verifyAttributeApiCall();
                    } else {
                      onSubmit(editedData);
                    }
                  } else {
                    if (nodeData?.type === 'incomingWA') {
                      setSaveKeywords(true);
                      oldEdgeDelete();
                    } else if (nodeData?.type !== 'metaPixelNode') {
                      console.log('modal save clicked');
                      onSubmit(editedData);
                    }
                  }
                }}
              />
              <CheerioButton
                borderStyle={{ width: '45%', border: `1px solid ${colors.black}` }}
                textStyle={{
                  fontSize: 16,
                  padding: 0,
                  margin: 0,
                  fontWeight: 600,
                  color: colors.black,
                }}
                btnText={'Delete Node'}
                backColor={colors.white}
                onclick={() => {
                  eventsend('Delete_node_workflow_builder_page_clicked');
                  console.log('node delete clicked');
                  onDelete();
                }}
              />
            </div>

            {/* ------------------- */}
          </div>
        </div>
      </div>
    </>
  );
};

export default EditNodeModal;
