import { useEffect, useRef, useState } from 'react';
import './styles.css';
import images from '../../../Utils/images';
import { IconButton, Skeleton, Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
// import { Comments } from './Comments';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { Select } from '@mui/material';
import {
  AddEditAttributes,
  getClientAttributes,
  fetchContactLabelList,
  contactlabelDeleteAPI,
  deleteLableContact,
  fetchContactLabels,
  AddEditcustomAttributes
} from '../../../Services';
import { CardImg } from 'react-bootstrap';
// import { MailRepliesModal } from './MailRepliesModal';
import colors from '../../../Utils/colors';
import UserAttributesModal from '../../WhatsAppReplies/components/UserAttributesModal';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../../Components/CheerioButton';
import styled from 'styled-components';
import LabelNamesModal from './labelNamesModal'
import { eventsend } from '../../../Config/analyticsFunctions';
const Container = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const SelectWrapper = styled(Select)`
  height: 24px;
  width: auto;
  border-radius: 12px;
  font-size: 12px;
  background-color: ${(props) => props.bgColor || 'white'};
  color: ${(props) => (props.resolvedValue === 'Unresolved' ? 'black' : 'white')};
  & .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '1px solid #74D772'
      : props.resolvedValue === 'Pending'
        ? '1px solid #FFAA00'
        : '1px solid #000000'};
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '1px solid #74D772'
      : props.resolvedValue === 'Pending'
        ? '1px solid #FFAA00'
        : '1px solid #000000'};
  }
  & .MuiSvgIcon-root {
    color: ${(props) => (props.resolvedValue === 'Unresolved' ? 'black' : 'white')};
  }
  &:hover {
    background-color: ${(props) =>
    props.resolvedValue === 'Resolved'
      ? '#74D772'
      : props.resolvedValue === 'Pending'
        ? '#FFAA00'
        : 'white'};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-block: 8px;
  flex: 1;
  padding: '8px 16px';
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  border: 1px solid grey;
  border-radius: 10px;

`;

const Field = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  width: 35%;
  max-width: 100px;
  padding: 8px 16px;
  border-right: 1px solid grey;
  overflow: clip;
  text-overflow: ellipsis;
`;

const ValueField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  flex: 1;
`;

const Value = styled.p`
  font-size: 16px;
  word-break: break-all;
  text-overflow: ellipsis;
`;

const EditableInput = styled.input`
  font-size: 16px;
  word-break: break-all;
  border: none;
  outline: none;
  background: transparent;
  color: inherit;
  width: '90%';
  text-overflow: ellipsis;
`;

const AddAttributeButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  border-radius: 6px;
  border: 1px solid #000;
  cursor: pointer;
  padding: 6px 16px;
  font-size: 14px;
  font-weight: 400;
`;
const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; /* Adjust gap as needed */
`;
const AddNewAttribute = ({ name,userNumber, setSelected,contact_id, updateattributes }) => {
  const authtoken = useSelector((state) => state.main.auth_token);
  const [AttrField, setAttrField] = useState('');
  const [AttrValue, setAttrValue] = useState('');
  const[userNumbers,setuserNumber] = useState(userNumber);
  const addAttributesCheck = (attr, value, name, userNumber ) => {
    let token = authtoken;
    let change = {};
    change[attr] = value;
    let data = {
      contact_id:contact_id,
      newChange: change,
    };
    console.log("data",data)
    AddEditcustomAttributes(token, data).then((res) => {
      if (res.status == 200) {
        updateattributes();
        toast.success('Attribute successfully updated!', {
          autoClose: true,
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          autoClose: true,
          position: 'top-right',
        });
      }
      setAttrField('');
      setAttrValue('');
    });
  };

  return (
    <>
      <div
        className="h-100 w-100 d-flex flex-column justify-content-start align-items-center"
        style={{ overflowY: 'auto', paddingInline: 24, paddingBlock: 20 }}
      >
        <div className="d-flex flex-row justify-content-start align-items-center w-100">
          <img
            src={images.CMClose}
            style={{
              height: 24,
              width: 24,
              objectFit: 'contain',
              cursor: 'pointer',
              marginInlineEnd: 16,
            }}
            onClick={() => setSelected('details')}
          ></img>
          <p style={{ fontSize: 16, fontWeight: 700 }}>{'Add new custom attribute'}</p>
        </div>
        <div className="py-4 w-100">
          <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04 }}>
            {
              'Only alphanumeric characters (letter A-Z, numbers 0-9) and underscores are allowed. You cannot use reserved field names.'
            }
          </p>
        </div>
        <InputGroup className="mt-3 mb-2 w-100">
          <Form.Label style={{ fontSize: 16, fontWeight: 600 }}>{'Field Name'}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter field name"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={AttrField}
            onChange={(e) => {
              setAttrField(e.target.value);
            }}
          />
        </InputGroup>
        <InputGroup className="mb-3 w-100">
          <Form.Label style={{ fontSize: 16, fontWeight: 600 }}>{'Field Value'}</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter field value"
            className="w-100 btncustom"
            style={{ borderRadius: 8 }}
            value={AttrValue}
            onChange={(e) => {
              setAttrValue(e.target.value);
            }}
          />
        </InputGroup>
        <div className="d-flex flex-row justify-content-around align-items-center w-100 mt-4">
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.black}`, width: '40%' }}
            textStyle={{}}
            btnText={'Cancel'}
            backColor={colors.white}
            onclick={() => setSelected('details')}
          />
          <CheerioButton
            borderStyle={{ border: `1px solid ${colors.primary03}`, width: '40%' }}
            textStyle={{ color: colors.white }}
            btnText={'Create Field'}
            backColor={colors.primary03}
            disabled={AttrField?.length > 0 && AttrValue?.length > 0 ? false : true}
            onclick={() => addAttributesCheck(AttrField, AttrValue)}
          />
        </div>
      </div>
    </>
  );
};
const EditableField = ({ fieldName, initialValue, activeEditing, addAttributesCheck }) => {
  const [value, setValue] = useState(initialValue);
  const [currentEditing, setCurrentEditing] = useState(false);
  const [allLabels, setAllLabels] = useState([]);
  const inputRef = useRef(null);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    console.log("33333333333")
    if (currentEditing) {
      // if (currentEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [currentEditing]);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <EditableInput
        ref={inputRef}
        value={value}
        onChange={handleChange}
        readOnly={!currentEditing}
      />
      {!activeEditing && (
        <div
          onClick={() => {
            if (currentEditing) {
              addAttributesCheck(fieldName, value);
            }
            setCurrentEditing((p) => !p);
          }}
        >
          <img
            alt={currentEditing ? 'Save button' : 'Edit button'}
            src={currentEditing ? images.TickSelIcon : images.EditIcon}
            style={{ height: 24, width: 'auto', cursor: 'pointer' }}
          />
        </div>
      )}
    </div>
  );
};

const Details = ({
  contact_id,
    name,
    userNumber,
    useremail,
  setShow,
  attributesObject,
  updateattributes,
  userAttributes,
  addNewAttributeAction,
}) => {
  const [details, setDetails] = useState();
  const authtoken = useSelector((state) => state.main?.auth_token); 
  const localdata = useSelector((state) => state.main?.localdata); 
  const [UAModalOpen, setUAModalOpen] = useState(false);
  const [deletefield, setDeletefield] = useState('');
  const [showMore, setShowMore] = useState(false);
  const[userNumbers,setuserNumber] = useState(userNumber);
  const [showDetails, setShowDetails] = useState(true); 

  const handleClose = () => {
    setShowDetails(false);
    setShow(false);
  };
  const addAttributesCheck = (attr, value,) => {
    let token = authtoken;
    let change = {};
    change[attr] = value;
    let data = {
      contact_id:contact_id,
      newChange: change,
    };
    AddEditcustomAttributes(token, data).then((res) => {
      if (res?.status === 200) {
        updateattributes();
        setTimeout(() => {
          scrollToComponent(attr);
        }, 1000);
        toast.success('Attribute successfully updated!', {
          autoClose: 500,
          position: 'top-right',
        });
      } else {
        toast.error('Attribute could not be updated, please try again later', {
          autoClose: 1000,
          position: 'top-right',
        });
      }
    });
  };
 console.log("delete field" ,deletefield)
  const selectedAttributeAction = (keyValue) => {
    if (keyValue in attributesObject) {
      toast('Attribute already exists', { autoClose: true, position: 'top-right' });
    } else {
      addAttributesCheck(keyValue, 'NA');
    }
    setUAModalOpen(false);
  };

  const scrollToComponent = (attr) => {
    const contentComponents = document.querySelectorAll('.content-component');
    contentComponents.forEach((component) => {
      const field = component.getElementsByClassName('field-class')[0]?.innerText;
      if (field === attr) {
        component.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });
  };
  const [activeEditing, setActiveEditing] = useState(false);

  const handleAttributeButtonClick = (fieldName) => {
    if (activeEditing) {
      setActiveEditing(false);
      return;
    }
  };
  const handleDelete = (key) => {
    addAttributesCheck(key, '--');
  };

  return (
    <Container>
      <Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '12px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: 48,
              height: 48,
              borderRadius: '50%',
              backgroundColor: colors.themeBlue,
              color: colors.white,
              fontWeight: 600,
              fontSize: 20,
            }}
          >
            {name?.substring(0, 1) ?? "N"}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '6px',
            }}
          >
            <h3
              style={{
                color: colors.black,
                fontWeight: '700',
                fontSize: '16px',
                textWrap: 'nowrap',
              }}
            >
              {name}
            </h3>
            <h4 style={{ color: colors.greys04, fontSize: '14px', fontWeight: '500' }}>
              {/* +91{' '} */}
              {(userNumber.replace(/^91/, '').slice(-10) || '').replace(
                  /(\d{5})(\d{5})/,
                  '$1-$2'
                )}
            </h4>
          </div>
        </div>
        <IconContainer>
          <Tooltip title="Copy Number" style={{ border: '1px solid red' }}>
            <img
              src={images.Copy}
              height={22}
              width={22}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                eventsend("Copy_details_Side_bar_Inbox_page_clicked");
                navigator.clipboard.writeText(
                    userNumber?.includes('+')
                    ? userNumber
                    : `+${userNumber}`
                );
                toast.success('Copied to clipboard');
              }}
            />
          </Tooltip>
          <Tooltip title="Close Details">
            <img
              src={images.CMClose} 
              height={22}
              width={22}
              style={{ cursor: 'pointer' }}
              onClick={handleClose} 
            />
          </Tooltip>
          </IconContainer>

      </Header>
      <ContentWrapper style={{ overflowX: 'hidden' }}>
        <Header style={{ padding: '8px 0px' }}>
          <h3 style={{ fontSize: '14px', fontWeight: 700 }}>Custom Attributes</h3>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <IconButton
              size="small"
              sx={{
                backgroundColor: colors.grey,
                borderRadius: '10px',
              }}
              onClick={() => {
                setActiveEditing((p) => !p);
              }}
            >
              <img
                alt={activeEditing ? 'save button' : 'edit button'}
                src={activeEditing ? images.TickSelIcon : images.EditIcon}
                style={{ height: 20, width: 'auto' }}
              />
            </IconButton>
            <CardImg
              className="filterToggleHover"
              src={images.PlusIcon}
              onClick={() => {
                if (userAttributes?.length > 0) {
                  setUAModalOpen(true);
                } else {
                  toast.error(
                    'Currently no other attributes can be added, please upload a csv with additional attributes.',
                    { autoClose: true, position: 'top-right' }
                  );
                }
              }}
              style={{
                cursor: 'pointer',
                // border: '1px solid red',
                marginLeft: '5px',
                height: 30,
                width: 30,
                borderRadius: '10px',
                padding: '6px',
                backgroundColor: colors.grey,
              }}
            />
          </div>
        </Header>
        <Content style={{ position: 'relative' }}>
          {UAModalOpen && (
            <UserAttributesModal
              setUserAttributesOpen={setUAModalOpen}
              userAttributesData={userAttributes}
              selectedUserAttributeAction={selectedAttributeAction}
              positionStyle={{ top: '0%', left: '0%', width: 320 }}
              repliesSidebar
              attributesObject={attributesObject}
              addNewAttributeAction={addNewAttributeAction}
            />
          )}
          <Field>Name</Field>
          <ValueField style={{ width: '100%' }}>
            <EditableField
              fieldName="name"
              initialValue={name}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck}
            />
          </ValueField>
        </Content>
        <Content>
        <Field>Phone no.</Field>
          <ValueField>
            <EditableField
              fieldName="userNumber"
              initialValue={userNumber}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck}
            />
          </ValueField>
        </Content>
        <Content>
        <Field>Email</Field>
          <ValueField>
            <EditableField
              fieldName="useremail"
              initialValue={useremail}
              activeEditing={activeEditing}
              addAttributesCheck={addAttributesCheck}
            />
          </ValueField>
        </Content>

        {attributesObject &&
          Object.keys(attributesObject).length !== 0 ? (
          <>
            {
              Object.entries(attributesObject)
              .slice(0, showMore ? Object.keys(attributesObject).length : 2)
                .map(([key, value]) => (
                  <Content key={key}>
                    <Field>{key}</Field>
                    <ValueField style={{ width: '100%' }}>
                      <EditableField fieldName={key} initialValue={value ?? '--'} activeEditing={activeEditing} addAttributesCheck={addAttributesCheck} />
                      {activeEditing && (
                        <div
                          onClick={() => {
                              handleDelete(key)
                          }}
                        >
                          <img
                            alt={'Delete button'}
                            src={images.TrashSelIcon}
                            style={{ height: 24, width: 'auto', cursor: 'pointer' }}
                            
                          />
                        </div>
                      )}
                    </ValueField>
                  </Content>
                ))
            }
          </>
        ) : attributesObject &&
          Object.keys(attributesObject).length === 0 ? (
          <></>
        ) : (
          <>
            <Skeleton variant="rectangular" width={'100%'} height={44} style={{
              marginBlockStart: 15,
              borderRadius: 10,
            }} />
            <Skeleton variant="rectangular" width={'100%'} height={44} style={{
              marginBlockStart: 15,
              borderRadius: 10,
            }} />
          </>

        )
        }
        {attributesObject && Object.keys(attributesObject).length > 2 && (
          <div className="d-flex flex-row justify-content-end align-items-center w-100 my-2">
            <p
              onClick={() => {
                if (!showMore) {
                  eventsend("Show_more_Side_bar_Inbox_page_clicked");
                } else {
                  eventsend("Show_less_Side_bar_Inbox_page_clicked");
                }
                setShowMore(!showMore);
              }}
              style={{ color: colors.primary03, cursor: 'pointer' }}
            >{`${showMore ? 'Show less' : 'Show more'}`}</p>
          </div>
        )}
      </ContentWrapper>
    </Container>
  );
};

export const EditableLabelList = ({
  labels = [],
  tocalllatestlables,
  name,
  contact_id,
  userNumber,
  useremail,
  updateLables,
}) => {
  const [editableLabels, setEditableLabels] = useState(labels);
  const [isEditing, setIsEditing] = useState(false);
  const [labelModalOpen, setLabelModalOpen] = useState(false);
  const [labelNames, setLabelNames] = useState([]);
  const authtoken = useSelector((state) => state.main?.auth_token); 

  useEffect(() => {
    setEditableLabels(labels);
  }, [labels]);

  useEffect(() => {
    if (authtoken) {
      fetchContactLabelList(authtoken).then((res) => {
        if (res?.flag) {
          const fetchedLabels = res.data?.v2?.map((item) => item.label_name) ?? [];
          setLabelNames(fetchedLabels);
        } else {
          console.error('Failed to fetch labels:', res);
        }
      });
    }
  }, [authtoken]);

  const handleLabelChange = (index, newValue) => {
    const updatedLabels = [...editableLabels];
    updatedLabels[index].name = newValue;
    setEditableLabels(updatedLabels);
  };

  const handleLabelDelete = (index) => {
    const labelToDelete = editableLabels[index];
    const data = {
      id: contact_id,
      labelId: labelToDelete?.id,
    };

    deleteLableContact(data, authtoken)
      .then((response) => {
        if (response?.flag) {
          const updatedLabels = editableLabels.filter((_, i) => i !== index);
          setEditableLabels(updatedLabels);
          toast.success('Label deleted successfully', {
            autoClose: 4000,
            position: 'top-right',
          });
          tocalllatestlables?.(); 
        } else {
          console.error('Failed to delete label:', response);
        }
      })
      .catch((error) => {
        console.error('Error in deleting label:', error);
      });
  };

  const handleSave = () => {
    setIsEditing(false);
  };

  return (
    <div style={{ padding: '3px', borderRadius: '10px', marginBottom: '16px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '16px',
          padding: '0px 6px',
        }}
      >
        <h3 style={{ fontSize: '14px', fontWeight: 700 }}>Associated Labels</h3>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <IconButton
            size="small"
            sx={{ backgroundColor: '#eee', borderRadius: '10px' }}
            onClick={() => (isEditing ? handleSave() : setIsEditing(true))}
          >
            <img
              alt={isEditing ? 'Save button' : 'Edit button'}
              src={isEditing ? images.TickSelIcon : images.EditIcon}
              style={{ height: 20, width: 'auto' }}
            />
          </IconButton>
          <IconButton
            size="small"
            sx={{ backgroundColor: '#eee', borderRadius: '10px' }}
            onClick={() => setLabelModalOpen(true)}
          >
            <img alt="Add label" src={images.PlusIcon} style={{ height: 20, width: 'auto' }} />
          </IconButton>
        </div>
      </div>

      <div className="d-flex flex-wrap gap-3 mt-3">
        {editableLabels?.map((label, index) => (
          <div
            key={index}
            className="d-flex align-items-center gap-2"
            style={{
              width: '165px',
              height: '40px',
              padding: '8px',
              marginLeft: '8px',
              borderRadius: '10px',
              backgroundColor: '#f7f7f7',
              position: 'relative',
              opacity: isEditing ? 0.8 : 0.8,
            }}
          >
            <Form.Control
              type="text"
              value={label?.name ?? ''}
              onChange={(e) => handleLabelChange(index, e.target.value)}
              readOnly={!isEditing}
              style={{
                border: 'none',
                backgroundColor: '#f7f7f7',
                width: '100%',
                paddingRight: isEditing ? '6px' : '0',
                fontSize: '14px',
                fontFamily: 'Segoe UI',
              }}
            />
            {isEditing && (
              <img
                alt="Delete button"
                src={images.TrashSelIcon}
                style={{
                  height: 24,
                  width: 'auto',
                  cursor: 'pointer',
                  position: 'absolute',
                  right: '10px',
                  top: '10px',
                  opacity: 0.8,
                }}
                onClick={() => handleLabelDelete(index)}
              />
            )}
          </div>
        ))}
      </div>
      {labelModalOpen && (
        <LabelNamesModal
          setLabelNamesOpen={setLabelModalOpen}
          selectedLabelAction={(label) => {
            setEditableLabels((prevLabels) => [...prevLabels, { name: label }]); 
            updateLables?.(); 
            setLabelModalOpen(false);
          }}
          labelNames={labelNames}
          tocalllatestlables={tocalllatestlables}
          username={name}
          userNumber={userNumber}
          useremail={useremail}
          updateLables={updateLables}
          positionStyle={{ top: '190px', left: '60px', right: '45px' }}
        />
      )}
    </div>
  );
};


export const ContactSideBar = ({
  id,
  tocalllatestlables,
  name,
  userNumber,
  useremail,
  labels,
  setShow,
  attributes,
  updateattributes,
  selected,
  setSelected,
  updateLables
}) => {
  const authtoken = useSelector((state) => state.main?.auth_token);
  const MobileWidth = window?.innerWidth < 900; 
  const [userAttributes, setUserAttributes] = useState();
  
  console.log("customdata", attributes);

  useEffect(() => {
    getUserAttributesAPICall();
  }, []);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    
    if (!token) {
      console.error('Token is missing');
      return;
    }

    getClientAttributes(token)
      .then((res) => {
        if (res?.flag) {
          setUserAttributes(res?.data ?? []); 
        } else {
          console.error('User attributes fetching failed:', res);
        }
      })
      .catch((error) => {
        console.error('Failed to fetch user attributes:', error);
      });
  };

  return (
    <div className="contact-sidebar">
      <div style={{ width: MobileWidth ? '100%' : '370px' }}>
        {selected === 'details' && (
          <Details
            contact_id={id}
            name={name}
            userNumber={userNumber}
            useremail={useremail}
            setShow={setShow}
            attributesObject={attributes}
            updateattributes={updateattributes}
            userAttributes={userAttributes}
            addNewAttributeAction={() => setSelected('newAttribute')}
          />
        )}
        {selected === 'newAttribute' && (
          <AddNewAttribute
            name={name}
            userNumber={userNumber}
            setSelected={setSelected}
            contact_id={id}
            updateattributes={updateattributes}
          />
        )}
        <EditableLabelList
          labels={labels}
          tocalllatestlables={tocalllatestlables}
          name={name}
          contact_id={id}
          userNumber={userNumber}
          useremail={useremail}
          updateLables={updateLables}
        />
      </div>
    </div>
  );
};

