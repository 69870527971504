import React, { useEffect, useState } from 'react'
import colors from '../../../Utils/colors'
import CheerioButton from '../../../Components/CheerioButton'
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getExcelLabelByID, viewExcelCSV } from '../../../Services';
import { toast } from 'react-toastify';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import images from '../../../Utils/images';

const LabelPage = ({ selectedLabel }) => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const { id } = useParams();
    const navigate = useNavigate();
    const [rowsData, setRowsData] = useState([])

    useEffect(()=>{
        getExcelLabelByIDAPIcall();
    },[])

    const getExcelLabelByIDAPIcall = () => {
        let data = {
            _id: id,
        };
        let token = authtoken;
        getExcelLabelByID(token,data).then((res)=>{
            if(res?.flag){
                setRowsData(res?.data);
            }else{
                toast.error(res?.message,{autoClose:true,position:'top-right'})
            }
        })
    }

    const columns = [
        { id: 'loname', label: 'LO Name', width: '25%', align: 'start' },
        { id: 'lonumber', label: 'LO Phone.no', width: '20%', align: 'center' },
        { id: 'pendingbase', label: 'Pending Base', width: '20%', align: 'center' },
        { id: 'hospoc', label: 'HO SPOC', width: '20%', align: 'center' },
        { id: 'file', label: 'File', width: '15%', align: 'center' },
        // { id: 'action', label: 'Action', width: '15%', align: 'center' },
    ];

    const openImageInNewWindow = (imageData) => {
        const newWindow = window.open();
        if (newWindow) {
            newWindow.document.write(`<img src="${imageData}" alt="Opened Image" />`);
            newWindow.document.title = "Image Preview";
        }
    };

    const veiwFileAction = (rowItem) => {
        let token = authtoken;
        let data = {
            labelId: id,
            foreignKey: "loid",
            foreignKeyValue: rowItem?.customData?.loid,
        }
        viewExcelCSV(data,token).then((res)=>{
            if(res?.flag){
                openImageInNewWindow(res?.data?.s3Link);
            }else{
                toast.error(res?.message,{autoClose:true,position:'top-right'})
            }
        })
    }

  return (
    <>
    <div className='w-100 h-100 Column justify-content-start' style={{
        paddingBlock:32,
        paddingInline:40,
        overflow:'hidden'
    }}>
        <div className='Row w-100'>
            <div className='Row justify-content-start'>
                <img src={images.BackArrowBlack} style={{
                    height:20,
                    width:20,
                    objectFit:'contain',
                    cursor:'pointer',
                    marginInlineEnd:10,
                }} onClick={()=>{
                    navigate(-1);
                }} 
                ></img>
                <h3>
                    {`Excel Labels - ${selectedLabel?.listName ? selectedLabel?.listName : 'contacts'}`}
                </h3>
            </div>
            
            {/* <CheerioButton 
                borderStyle={{borderColor: colors.primary03}}
                backColor={colors.primary03}
                textStyle={{fontSize:16,fontWeight:600,color:colors.white01}}
                btnText={'Upload excel'}
            /> */}
        </div>
        <TableContainer sx={{ flex: 1 , marginBlock:'20px', overflowY:'auto'}}>
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        {columns.map((column) => {
                            return (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{
                                        width: column.width,
                                        fontSize: 16,
                                        fontWeight: 500,
                                        color: colors.black,
                                        backgroundColor: colors.white04,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rowsData?.length > 0 && rowsData.map((row) => {
                        return (
                            <>
                            <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                {columns.map((column) => {
                                    let value = '';
                                    if (column.id == 'loname') {
                                        value = row?.customData?.loname;
                                    } else if (column.id == 'lonumber'){
                                        value = row?.mobile;
                                    } else if (column.id == 'pendingbase') {
                                        value = row?.customData?.pendingbase ?? '0';
                                    } else if (column.id == 'hospoc') {
                                        value = row?.customData?.hospoc;
                                    }
                                    return (
                                        <TableCell key={column.id} align={column.align}>
                                            {column.id == 'action' ? (
                                                <>
                                                <div className="d-flex flex-row justify-content-center align-items-center">
                                                    <Tooltip title={'View'}>
                                                        <img
                                                            src={images.EUViewBlue}
                                                            style={{
                                                                marginInline: 6,
                                                                height: 18,
                                                                width: 18,
                                                                objectFit: 'contain',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                // navigate(`/creator/excelLabel/label/${row._id}`)
                                                                // onclickEditAction(row?._id);
                                                            }}
                                                        ></img>
                                                    </Tooltip>
                                                    <Tooltip title={'Delete'}>
                                                        <img
                                                            src={images.EUDeleteBlue}
                                                            style={{
                                                                marginInline: 6,
                                                                height: 18,
                                                                width: 18,
                                                                objectFit: 'contain',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => {
                                                                    // onclickDuplicateAction(row?._id);
                                                            }}
                                                        ></img>
                                                    </Tooltip>
                                                </div>
                                                </>
                                            ) : column.id == 'file' ? (
                                                <p style={{ fontSize: 14, color: colors.linkblue,cursor:'pointer' }} onClick={()=>{
                                                    veiwFileAction(row);
                                                }}>
                                                    {'View File'}
                                                </p>
                                            ) : (
                                                <p style={{ fontSize: 14, color: colors.black }}>
                                                    {value}
                                                </p>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                            </>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </div>
    </>
  )
}

export default LabelPage