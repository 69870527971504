import React, { useEffect, useState } from 'react';
import colors from '../../Utils/colors';
import CheerioButton from '../../Components/CheerioButton';
import images from '../../Utils/images';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getLocaltime } from '../../Utils/localdate'
import {
  emailNotClickedApi,
  getAnalyticsReportAPI,
  getAverageStatsAPI,
  getCampaignDetailsAPI,
  getCampaignListAPI,
  getUserSpecificReportAPI,
  getLifeTimeStatsAPI,
  getLifeTimeStatsAPIV2,
  downloadUserSpecificCsv,
  downloadSingleAnalyticsCsv
} from '../../Services';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import SpinnerLoader from '../../Components/SpinnerLoader';
import './styles.css';
import {
  getUserSpecificAnaylticsAPI,
  getOverallAnalyticsAPI,
  getSingleAnalyticsAPI,
} from '../../Services';
import moment from 'moment';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import AnnNewModal from '../AnnouncementsNew/Components/AnnNewModal';
import MyPagination from '../ImportContactsNew/Components/Pagination';
import { MailCsvModal } from './MailCsvModal';
import { toast } from 'react-toastify';
import { Tooltip as Tip, Toolbar } from '@mui/material';
import { FilterMenu } from './FilterMenu';
import { MailRepliesModal } from '../WhatsAppReplies/components/MailRepliesModal';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Pagination } from '@mui/material';
import { eventsend } from '../../Config/analyticsFunctions';
import { trackPages } from '../../Utils/brevo';
import Select from 'react-select';
export function hideWord(w) {
  w = String(w);
  if (w.length < 3) return w;
  return w.substring(0, 2) + '*'.repeat(w.length - 2);
}

export const hidePhoneNo = (number) => {
  return String(number).replace(/(\d{1})(.*)(\d{3})/, '$1******$3');
};

export const hideEmail = (email) => {
  return String(email).replace(/(\w{1})(.*)(\w{1})@(.*)/, '$1******$3@$4');
};

export const getLabelDate = (e) => {
  const date = new Date(e);
  const mm = date.toLocaleString('en-EN', { month: 'short' });
  const dd = date.getDate();
  return dd + '-' + mm;
};
const CreatorAnalyticsNew = ({ excepted }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const CID = location?.state?.CID;

  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const agentdata = useSelector((state) => state.main.agentdata);
  const campaignId = useSelector((state) => state.main.campaignId);

  const userID = useSelector((state) => state.main._id);
  // const [disable,setDisable] = useState(false);
  const [showAna, setShowAna] = useState(false);
  const [campaignList, setCampaignList] = useState();
  const [Cname, setCname] = useState('');
  const [channel, setChannel] = useState('WhatsApp');
  const [avgStats, setAvgStats] = useState('');
  const [campaignDetails, setCampaignDetails] = useState();

  const [gsent, setGsent] = useState(null);
  const [gdelivered, setGdelivered] = useState(null);
  const [gopened, setGopened] = useState(null);
  const [glinkCTR, setGlinkCTR] = useState(null);
  const [repliedClick, setRepliedClick] = useState(null);
  const [nsent, setNsent] = useState(null);
  const [ntotal, setNtotal] = useState(null);
  const [ndelivered, setNdelivered] = useState(null);
  const [nopened, setNopened] = useState(null);
  const [nlinkCTR, setNlinkCTR] = useState(null);
  const [nullValues, setNullValues] = useState(false);
  const [graphloading, setgraphloading] = useState(false);
  const [channelName, setChannelName] = useState('WhatsApp');
  const [userAnalytics, setUserAnalytics] = useState();
  const [analyticsLoading, setAnalyticsLoading] = useState(false);
  const [campaignName, setCampaignName] = useState();
  const [feature, showFeature] = useState(false);
  const [openANModal, setOpenANModal] = useState(false);
  const [retargetingLabelName, setRetargetingLabelName] = useState('');
  const [retargetingCampaignName, setRetargetingCampaignName] = useState('');
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [rows, setRows] = useState([]);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [show, setShow] = useState(false);
  const [showMailCsv, setShowMailCsv] = useState(false);
  const [userSpecificCsv, setUserSpecificCsv] = useState(false);
  const [details, setDetails] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [numberOfDate, setNumberOfDate] = useState('');
  const [date, setDate] = useState({
    startDate: null,
    endDate: null,
  });
  const [showMailCsvModal, setShowMailCsvModal] = useState(false);
  const [email, setEmail] = useState(localdata?.email);
  const [linkIndex, setLinkIndex] = useState(0);
  const [selectedItem, setSelectedItem] = useState(null);

  const mobileWidth = window.innerWidth < 700 ? true : false;
  const isPremiumUser = localdata?.premium;
  const specialStatus =
    localdata?._id == '63fdd1ea89e5e9509f0d7f78' || localdata?._id == '65ee7dd6362208d14232e2da';
  const dispatch = useDispatch();
  const [userSpecificAnalytics, setuserSpecificAnalytics] = useState();
  const [totalCampaignSent, settotalCampaignSent] = useState();
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dropdownpage, setDropdownPage] = useState(1); // Track the current page
  const [searchTerm, setSearchTerm] = useState(''); // Track the search term

  // Function to fetch campaign list based on search term and page
  const fetchCampaigns = async (page, searchData = '') => {
    setLoading(true);
    const res = await getCampaignListAPI(authtoken, page, 7, searchData, 'all', 'latest', 'all');
    if (res?.flag) {
      const newOptions = res?.data?.list?.docs.map((e) => ({
        value: e._id,
        label: formatCampaignLabel(e.announcement),
      }));
      setCampaignOptions((prev) => [...prev, ...newOptions]);
      setHasMore(newOptions.length > 0); // Check if there are more options
    } else {
      setHasMore(false); // No more options if the API call fails
    }
    setLoading(false);
  };


  const formatCampaignLabel = (announcement) => {
    const { label_name, date, template_name } = announcement;
    const formattedDate = getLabelDate(date);

    switch (label_name) {
      case 'customer_enquiry':
        return `Follow up with discount (${formattedDate}, ${template_name})`;
      case 'renewal_remainder':
      case 'renewal_reminder':
        return `Send a Reminder (${formattedDate}, ${template_name})`;
      case 'share_info':
        return `Share info/update/quote (${formattedDate}, ${template_name})`;
      case 'thank_you_customer':
        return `Thank your customer (${formattedDate}, ${template_name})`;
      case 'additional_discount_offer':
        return `Additional discount offer (${formattedDate}, ${template_name})`;
      case 'repeatcustomer_offer':
      case 'repeatcustomer_offer2':
        return `Repeat customer offer (${formattedDate}, ${template_name})`;
      case 'customer_win_back':
        return `Customer win back (${formattedDate}, ${template_name})`;
      case 'business_info_newsletter':
        return `Business info newsletter (${formattedDate}, ${template_name})`;
      case 'welcome_insta_message_2':
        return `Welcome Instagram (${formattedDate}, ${template_name})`;
      case 'loyal_customers_new':
        return `X% off with discount code (${formattedDate}, ${template_name})`;
      case 'discount_all_products_new':
        return `Thank you message (${formattedDate}, ${template_name})`;
      case 'CustomEmail':
        return `Custom HTML (${formattedDate}, ${template_name})`;
      case 'maintainance_update':
        return `Maintenance update (${formattedDate}, ${template_name})`;
      case 'new_product_service_marathi':
        return `New product service Marathi (${formattedDate}, ${template_name})`;
      case 'reply_multiple':
        return `Share awareness multiple replies (${formattedDate}, ${template_name})`;
      case 'do_you_know':
        return `Do you know (${formattedDate}, ${template_name})`;
      case 'Course':
      case 'Product':
        return `Sell a ${label_name} (${formattedDate}, ${template_name})`;
      case 'Offer':
        return `Share an ${label_name} (${formattedDate}, ${template_name})`;
      default:
        return `${label_name} (${formattedDate}, ${template_name})`;
    }
  };

  useEffect(() => {
    // Reset options and page when search term changes
    setCampaignOptions([]);
    setDropdownPage(1);
    setHasMore(true);
    fetchCampaigns(1, searchTerm); // Fetch initial campaigns with the search term
  }, [searchTerm]);

  useEffect(() => {
    if (dropdownpage > 1) {
      fetchCampaigns(dropdownpage, searchTerm); // Fetch campaigns on page change
    }
  }, [dropdownpage]);

  const loadMoreOptions = () => {
    if (!loading && hasMore) {
      setDropdownPage((prevPage) => prevPage + 1); // Increment page number for the next fetch
    }
  };

  // onload - agent permission check + campaign list api call
  useEffect(() => {
    trackPages('analytics_creator_analytics_page', localdata);
    setgraphloading(true);
    setAnalyticsLoading(true);
    eventsend('analytics_pageview_web');
    if (agentdata && !agentdata?.permission?.analytics) {
      toast.error(
        'Feature access denied. Kindly request the owner to grant you permission to access this feature'
      );
      navigate('/creator');
    }
    // getAverageStatsApiCall();

    fetchCampaigns(1,'');

    if (specialStatus) {
      setShow(true);
    }
  }, []);

  useEffect(() => {
    if (campaignOptions.length > 0 && !Cname) {
      setCname(campaignOptions[0].value);
      showFeature(true);
      window.history.pushState({}, '', `/creator/analytics?campaign=${campaignOptions[0].value}`);
    }
  }, [campaignOptions]);

  // FILLLLLLLLL
  useEffect(() => {
    if (CID) {
      setCname(CID);
    }
  }, [CID]);

  // useEffect(() => {
  //   console.log('useffect label name', retargetingCampaignName);
  //   console.log('useffect retlabel name', retargetingLabelName);
  // }, [retargetingCampaignName, retargetingLabelName]);

  // on campaign list set -> get filtered list call
  useEffect(() => {
    if (campaignList) {
      getFilteredList();
    }
  }, [campaignList]);

  // get filtered list call + setCname + setCampaignName
  const getFilteredList = () => {
    let FilteredList = campaignList;
    console.log('//////////////////////////////////');
    console.log('////', FilteredList[0]?._id);
    console.log('//////////////////////////////////');
    if (!Cname) {
      setCname(FilteredList[0]?._id);
      window.history.pushState({}, '', `/creator/analytics?campaign=${FilteredList[0]?._id}`);
    }
    // setCampaignName(campaignId ?? FilteredList[0]?.campaign_id);
  };
  // useEffect(() => {
  //   if (Cname) {
  //     window.history.pushState({}, '', `/creator/analytics?campaign=${Cname}`);
  //   }
  // }, [Cname]);

  // const getAverageStatsApiCall = () => {
  //   let data = {
  //     user_id: localdata?._id,
  //   };
  //   let token = authtoken;
  //   getLifeTimeStatsAPIV2(data, token).then((res) => {
  //     if (res.flag) {
  //       console.log('average stats ===> ', res);
  //       setAvgStats(res?.data);
  //     } else {
  //       console.log('err avg stats api ===> ', res);
  //     }
  //   });
  // };

  const handleClick = (channel, value) => {
    eventsend("Retarget_3_dots_Analytics_page_clicked");
    if (value) {
      setgraphloading(true);
      let data = {
        _id: Cname,
        Channel: channel,
        value: value,
      };
      let token = authtoken;
      emailNotClickedApi(data, token).then((res) => {
        if (res?.flag) {
          setgraphloading(false);
          console.log('email not clicked stats ===> ', res);
          const details = res?.data?.result?.retargetingDetail[0]?.label?.label_name;
          console.log('mad', details);
          setRetargetingLabelName(details);

          // retargetingLabelName = details?.label?.label_name;
          const name = campaignList.filter((item) => item?._id == Cname);
          setRetargetingCampaignName(`Retargeting (${name[0].announcement.template_name})`);

          setOpenANModal(true);
          //setAvgStats(res?.data);
        } else {
          setgraphloading(false);
          toast.error('Error in Re-Targeting: ', res?.message);
          console.log('err emailNotclicked api ===> ', res);
        }
      });
    }
  };

  // campaign list api call + setCampaignList
  // const campaignListApiCall = () => {
  //   let token = authtoken;
  //   getCampaignListAPI(token, 1, 500, '', 'all', 'latest', 'all').then((res) => {
  //     if (res?.flag) {
  //       setCampaignList(res?.data?.list?.docs);
  //       // let any = "Retargeting" +name[0].template_name;
  //       // setRetargetingCampaignName(any);
  //       // console.log("nameee",retargetingCampaignName)
  //       console.log('campaign list res ===> ', res);
  //     } else {
  //       console.log('err campaign list res ===> ', res);
  //     }
  //   });
  // };

  // Cname , channel change -> campaignDetailsAPICall
  const getQueryParams = (search) => {
    return new URLSearchParams(search);
  };

  useEffect(() => {
    const queryParams = getQueryParams(location.search);
    const campaignId = queryParams.get('campaign');

    if (campaignId) {
      setCname(campaignId);
    }
  }, [location.search]);

  useEffect(() => {
    if (Cname && channel) {
      console.log('Campaign selected ID ===> ', Cname);
      console.log('Channel selected ===>', channel);
      campaignDetailsApiCall();
      setAnalyticsLoading(true);
      userSpecificApiCall();
    }
  }, [Cname, channel]);

  useEffect(() => {
    if (!isPremiumUser) {
      setGsent(100);
      setGdelivered(88);
      setGopened(74);
      setGlinkCTR(41);
      setNsent('50K');
      setNtotal('50K');
      setNdelivered('48K');
      setNopened('36K');
      setNlinkCTR('12K');
      setShowAna(true);
      setgraphloading(false);
    }
  }, [isPremiumUser]);
  // channel , campaignDetails change -> set graph data
  useEffect(() => {
    //setgraphloading(true);
    //setAnalyticsLoading(true);
    if (channel) {
      console.log('channel changed to ===> ', channel);
      if (campaignDetails) {
        if (channel == 'Email') {
          setGsent(
            campaignDetails?.email_stats?.sentRate == null
              ? '0'
              : campaignDetails?.email_stats?.sentRate
          );
          setGdelivered(
            campaignDetails?.email_stats?.deliveryRate == null
              ? '0'
              : campaignDetails?.email_stats?.deliveryRate
          );
          setGopened(
            campaignDetails?.email_stats?.openedRate == null
              ? '0'
              : campaignDetails?.email_stats?.openedRate
          );
          setGlinkCTR(
            campaignDetails?.email_stats?.link_CTR == null
              ? '0'
              : campaignDetails?.email_stats?.link_CTR
          );
          setNsent(
            campaignDetails?.email_stats?.sent == null ? '0' : campaignDetails?.email_stats?.sent
          );
          setNtotal(
            campaignDetails?.email_stats?.total == null ? '0' : campaignDetails?.email_stats?.total
          );
          setNdelivered(
            campaignDetails?.email_stats?.delivery == null
              ? '0'
              : campaignDetails?.email_stats?.delivery
          );
          setNopened(
            campaignDetails?.email_stats?.opened == null
              ? '0'
              : campaignDetails?.email_stats?.opened
          );
          setNlinkCTR(
            campaignDetails?.email_stats?.link_Clicks == null
              ? '0'
              : campaignDetails?.email_stats?.link_Clicks
          );
        } else if (channel == 'SMS') {
          setGsent(campaignDetails?.sms?.sentRate == null ? '0' : campaignDetails?.sms?.sentRate);
          setGdelivered(
            campaignDetails?.sms?.deliveryRate == null ? '0' : campaignDetails?.sms?.deliveryRate
          );
          setGopened(
            campaignDetails?.sms?.openedRate == null ? '0' : campaignDetails?.sms?.openedRate
          );
          setGlinkCTR(
            campaignDetails?.sms?.link_CTR == null ? '0' : campaignDetails?.sms?.link_CTR
          );
          setNsent(campaignDetails?.sms?.sent == null ? '0' : campaignDetails?.sms?.sent);
          setNtotal(campaignDetails?.sms?.total == null ? '0' : campaignDetails?.sms?.total);

          setNdelivered(
            campaignDetails?.sms?.delivery == null ? '0' : campaignDetails?.sms?.delivery
          );
          setNopened(campaignDetails?.sms?.opened == null ? '0' : campaignDetails?.sms?.opened);
          setNlinkCTR(
            campaignDetails?.sms?.link_Clicks == null ? '0' : campaignDetails?.sms?.link_Clicks
          );
        } else if (channel == 'WhatsApp') {
          setGsent(
            campaignDetails?.whatsapp_stats?.sentRate == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.sentRate
          );
          setGdelivered(
            campaignDetails?.whatsapp_stats?.deliveryRate == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.deliveryRate
          );
          setGopened(
            campaignDetails?.whatsapp_stats?.openedRate == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.openedRate
          );
          setGlinkCTR(
            campaignDetails?.whatsapp_stats?.link_CTR == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.link_CTR
          );
          setNsent(
            campaignDetails?.whatsapp_stats?.sent == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.sent
          );
          setNtotal(
            campaignDetails?.whatsapp_stats?.total == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.total
          );
          setNdelivered(
            campaignDetails?.whatsapp_stats?.delivery == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.delivery
          );
          setNopened(
            campaignDetails?.whatsapp_stats?.opened == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.opened
          );
          setNlinkCTR(
            campaignDetails?.whatsapp_stats?.link_Clicks == null
              ? '0'
              : campaignDetails?.whatsapp_stats?.link_Clicks
          );
        }
      }
    }
    /* setTimeout(() => {
      setgraphloading(false);
      // setTimeout(() => {
      //   setAnalyticsLoading(false);
      // }, 3000);
    }, 500); */
  }, [channel, campaignDetails]);

  useEffect(() => {
    if (!Cname) {
      setNullValues(false);
    } else if (gsent == null || gdelivered == null || gopened == null || glinkCTR == null) {
      setNullValues(true);
    }
  }, [gsent, gdelivered, gopened, glinkCTR]);

  useEffect(() => {
    if (campaignDetails) {
      if (channel) {
        setShowAna(true);
        if (channel == 'Email') {
          if (campaignDetails?.email_stats?.total == 0) {
            setDetails(false);
          } else {
            setDetails(true);
          }
        } else if (channel == 'WhatsApp') {
          if (campaignDetails?.whatsapp_stats?.total == 0) {
            setDetails(false);
          } else {
            setDetails(true);
          }
        } else if (channel == 'SMS') {
          if (campaignDetails?.sms?.total == 0) {
            setDetails(false);
          } else {
            setDetails(true);
          }
        }
      }
    }
  }, [channel, campaignDetails]);

  // campaignDetailsAPICall + setCampaignDetails
  const campaignDetailsApiCall = () => {
    setgraphloading(true);
    let data = {
      campaign_id: Cname,
      channel: channel,
    };
    let token = authtoken;
    getCampaignDetailsAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log('campaign details ====> ', res);
        let total_whatsapp_stats = res.data.whatsapp_stats.total;
        let email_stats = res.data.email_stats.total
        let sms_stats = res.data.sms.total
        let max_campaign = Math.max(total_whatsapp_stats, email_stats, sms_stats)
        settotalCampaignSent(max_campaign)
        console.log('total total stats  ====> ', max_campaign);
        setCampaignDetails(res?.data);
        setgraphloading(false);
      } else {
        console.log('err campaign details ===>', res);
        setgraphloading(false);
      }
    });
  };

  // FILLLLLLL
  const userSpecificApiCall = (page = 1) => {
    let data = {
      // campaign_id: campaignName,
      campaign_id: Cname,
      // channel: channelName,
      channel: channel,
      page: page,
      hyperLinkIndex: linkIndex,
    };
    let token = authtoken;
    getUserSpecificAnaylticsAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log('User Specific Anayltics details are as follows ====> ', res);
        console.log('Only Fetching Particular Data ====> ', res.data.campaignAnalysis);
        setuserSpecificAnalytics(res.data.result.campaignAnalysis.length)
        console.log("*********", res.data.result.campaignAnalysis.length)
        setPaginationData(res?.data?.result?.paginateDetails);
        setUserAnalytics(res?.data?.result?.campaignAnalysis);
        setRows(res?.data?.result?.campaignAnalysis);
      } else {
        console.log('err campaign details ===>', res);
      }
      setAnalyticsLoading(false);
    });
  };

  // merge into single cname
  // useEffect(() => {
  //   if (campaignName && channelName) {
  //     console.log('come va', campaignId, campaignName);

  //     setAnalyticsLoading(true);
  //     userSpecificApiCall();
  //     setTimeout(() => {
  //       // setAnalyticsLoading(false);
  //       dispatch({ type: 'addCampaignId', payload: null });
  //     }, 2000);
  //   }
  // }, [channelName, campaignName]);

  useEffect(() => {
    if (page) {
      console.log('page change trigerred', page);
      userSpecificApiCall(page);
    }
  }, [page]);

  // new table code

  useEffect(() => {
    if (rows) {
      console.log('////////////////// ', rows);
    }
  }, [rows]);

  const handleChangePage = (event, newPage) => {
    console.log('new page ------> ', newPage);
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(1);
  // };
  const columns = [
    {
      id: 'name',
      label: 'Name',
      minWidth: 144,
      align: 'start',
      width: '16%',
      format: (value) => {
        if (value === 'There') {
          return 'NA';
        } else {
          return show ? value : hideWord(value);
        }
      },
    },
    {
      // id: channelName === 'Email' ? 'email' : 'mobile',
      id: channel === 'Email' ? 'email' : 'mobile',
      // id: 'mobile',
      // label: channelName === 'Email' ? 'Email' : 'Phone no.',
      label: channel === 'Email' ? 'Email' : 'Phone no.',
      minWidth: 144,
      align: 'start',
      width: '16%',
      format: (value) => {
        // if (channelName === 'Email') {
        if (channel === 'Email') {
          return show ? value : hideEmail(value);
        } else {
          return show ? value : hidePhoneNo(value);
        }
      },
    },
    {
      id: 'send',
      label: 'Sent',
      minWidth: 153,
      align: 'center',
      width: '17%',
      format: (value) => {
        if (value) {
          // const fd = formatDate(value);
          // const ft = formatTime(value);
          // return fd + ', ' + ft;
          return getLocaltime(value)
        } else {
          return '--';
        }
      },
    },
    {
      id: 'deliveryAt',
      label: 'Delivered',
      minWidth: 153,
      align: 'center',
      width: '17%',
      format: (value) => {
        if (value) {
          // console.log("*****************",value)
          // const fd = formatDate(value);
          // const ft = formatTime(value);
          // return fd + ', ' + ft;
          return getLocaltime(value)
        } else {
          return '--';
        }
      },
    },
    {
      id: 'read',
      label: 'Read',
      minWidth: 153,
      align: 'center',
      width: '17%',
      format: (value) => {
        if (value) {
          // const fd = formatDate(value);
          // const ft = formatTime(value);
          // return fd + ', ' + ft;
          return getLocaltime(value)
        } else {
          return '--';
        }
      },
    },
    {
      id: 'click',
      label: 'Link Clicked',
      minWidth: 153,
      align: 'center',
      width: '17%',
      format: (value) => {
        if (value) {
          // const fd = formatDate(value);
          // const ft = formatTime(value);
          // return fd + ', ' + ft;
          return getLocaltime(value)
        } else {
          return '--';
        }
      },
    },
  ];

  // -----------X---------------X--------------

  function formatTime(timestamp) {
    let hhmm = moment(timestamp).format('LT');
    return hhmm;
  }
  function formatDate(date) {
    let new_date = new Date(date);
    let year = new_date.getFullYear();
    let month = String(new_date.getMonth() + 1).padStart(2, '0');
    let day = String(new_date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        eventsend("Retarget_3_dots_Analytics_page_clicked");
        onClick(e);
      }}
    >
      {children}
      <Tip title={'Retarget'}>
        <img src={images.ThreeDot} style={{ height: 30, width: 30 }} />
      </Tip>
    </a>
  ));

  const AvgStatsCard = ({ value, bottombordercolor, icon, icontext, borderR = true }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-center"
          style={{
            width: '30%',
            borderRight: borderR ? `1px solid ${colors.borderwhite}` : '',
            paddingInline: 16,
          }}
        >
          <p
            style={{
              padding: 0,
              margin: 0,
              marginBlock: 16,
              fontSize: 48,
              fontWeight: 700,
              color: colors.black,
            }}
          >
            {value == 'NA' ? `${value}` : `${value}`}
          </p>
          <div
            className="d-flex flex-row justify-content-start align-items-center w-100"
            style={{ borderBottom: `2px solid ${bottombordercolor}`, paddingBlock: 4 }}
          >
            <img src={icon} style={{ height: 24, width: 24, objectFit: 'contain' }}></img>
            <p
              style={{
                padding: 0,
                margin: 0,
                marginInlineStart: 8,
                fontSize: 18,
                fontWeight: 600,
                color: colors.greys04,
              }}
            >
              {icontext}
            </p>
          </div>
        </div>
      </>
    );
  };

  const CampaignStatsCard = ({ text, value, textnumber }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-start align-items-start stat_card_item_container"
          style={{ paddingInline: 4, marginInline: 4 }}
        >
          <div
            className="d-flex flex-row justify-content-between align-items-start w-100"
            style={{}}
          >
            <p
              style={{ padding: 0, margin: 0, fontSize: 16, fontWeight: 500, color: colors.black }}
            >
              {text}
              <br />
              {text == 'Opened' && channel == 'SMS' && (
                <span style={{ fontSize: 12, fontWeight: 400, color: colors.greys04 }}>
                  {'(Metric not available for SMS)'}
                </span>
              )}
            </p>
            <div className="d-flex flex-row align-items-end" style={{}}>
              <p style={{ padding: 0, margin: 0, fontSize: 18, fontWeight: 500, color: '#008BAD' }}>
                {textnumber}
              </p>
              {text !== 'Sent' && text !== 'Total' && channel !== 'SMS' && (
                <Dropdown>
                  <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>
                  {/* {text === "Sent" &&
      <Dropdown.Menu>
      <Dropdown.Header style={{color:"black",fontWeight:"600",fontSize:"16px"}}><img src={images.Announce}></img>Choose who to retarget</Dropdown.Header>
        <Dropdown.Item href="#/action-1">Sent Contacts</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Not sent contacts</Dropdown.Item>

      </Dropdown.Menu>} */}

                  {/* {text === "Delivered" &&
      <Dropdown.Menu>
      <Dropdown.Header style={{color:"black",fontWeight:"600",fontSize:"16px"}}><img src={images.Announce}></img>Choose who to retarget</Dropdown.Header>
        <Dropdown.Item href="#/action-1">Delivered Contacts</Dropdown.Item>
        <Dropdown.Item href="#/action-2">Not delivered contacts</Dropdown.Item>

      </Dropdown.Menu>}   */}

                  {text === 'Delivered' && (
                    <Dropdown.Menu>
                      <Dropdown.Header
                        style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                      >
                        <img src={images.Announce}></img>
                        {'Choose who to retarget'}
                      </Dropdown.Header>
                      {
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'delivered');
                            eventsend("Retarget_3_dots_Analytics_page_clicked");
                          }}
                        >
                          {channel} contacts delivered
                        </Dropdown.Item>
                      }
                      {
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'not_delivered');
                          }}
                        >
                          {channel} contacts undelivered
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  )}
                  {text === 'Opened' && (
                    <Dropdown.Menu>
                      <Dropdown.Header
                        style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                      >
                        <img src={images.Announce}></img>
                        {'Choose who to retarget'}
                      </Dropdown.Header>
                      {
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'opened');
                            eventsend("Retarget_3_dots_Analytics_page_clicked");
                          }}
                        >
                          {channel} contacts who opened
                        </Dropdown.Item>
                      }
                      {
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'not_opened');
                          }}
                        >
                          {channel} contacts who didn't open
                        </Dropdown.Item>
                      }
                    </Dropdown.Menu>
                  )}

                  {text === 'Clicks' && (
                    <Dropdown.Menu>
                      <Dropdown.Header
                        style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                      >
                        <img src={images.Announce}></img>Choose who to retarget
                      </Dropdown.Header>
                      <Dropdown.Item
                        onClick={() => {
                          handleClick(channel, 'clicked');
                          eventsend("Retarget_3_dots_Analytics_page_clicked");
                        }}
                      >
                        {channel} contacts who clicked
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          handleClick(channel, 'not_clicked');
                        }}
                      >
                        {channel} contacts who didn't clicked
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              )}
              {text !== 'Sent' &&
                text !== 'Delivered' &&
                text !== 'Opened' &&
                channel === 'SMS' &&
                text !== 'Total' && (
                  <Dropdown>
                    <Dropdown.Toggle as={CustomToggle}></Dropdown.Toggle>

                    {text === 'Clicks' && (
                      <Dropdown.Menu>
                        <Dropdown.Header
                          style={{ color: 'black', fontWeight: '600', fontSize: '16px' }}
                        >
                          <img src={images.Announce}></img>Choose who to retarget
                        </Dropdown.Header>
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'clicked');
                            eventsend("Retarget_3_dots_Analytics_page_clicked");
                          }}
                        >
                          {channel} contacts who clicked
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => {
                            handleClick(channel, 'not_clicked');
                          }}
                        >
                          {channel} contacts who didn't clicked
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    )}
                  </Dropdown>
                )}
            </div>
          </div>
          <Tip title={text !== 'Total' && 'as a percentage of last step'}>
            <p
              className="stat_card_percentage"
              style={{
                padding: 0,
                margin: 0,
                // fontSize: 48,
                fontWeight: 700,
                color: colors.black,
                marginBlock: -8,
              }}
            >
              {text == 'Opened' && channel == 'SMS' ? 'NA' : `${value}%`}
            </p>
          </Tip>
        </div>
      </>
    );
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler
    // Legend
  );

  const data = {
    labels: ['Sent', 'Delivered', 'Opened', 'Link Clicks', ''],
    datasets: [
      {
        data: [gsent, gdelivered, null, null, null],
        fill: true,
        backgroundColor: '#008BAD',
        borderColor: '#008BAD',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [-gsent, -gdelivered, null, null, null],
        fill: true,
        backgroundColor: '#008BAD',
        borderColor: '#008BAD',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, gdelivered, gopened, null, null],
        fill: true,
        backgroundColor: '#009FC7',
        borderColor: '#009FC7',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, -gdelivered, -gopened, null, null],
        fill: true,
        backgroundColor: '#009FC7',
        borderColor: '#009FC7',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, gopened, glinkCTR == null ? -glinkCTR : glinkCTR, null],
        fill: true,
        backgroundColor: '#00B3E0',
        borderColor: '#00B3E0',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, -gopened, -glinkCTR, null],
        fill: true,
        backgroundColor: '#00B3E0',
        borderColor: '#00B3E0',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, null, glinkCTR, glinkCTR],
        fill: true,
        backgroundColor: '#00C2F2',
        borderColor: '#00C2F2',
        tension: 0.4,
        pointRadius: 0,
      },
      {
        data: [null, null, null, -glinkCTR, -glinkCTR],
        fill: true,
        backgroundColor: '#00C2F2',
        borderColor: '#00C2F2',
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const options = {
    responsive: true,

    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          color: '#fff',
          width: '4px',
        },
        ticks: {
          display: false,
        },
      },
      y: {
        min: -110,
        max: 110,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 20,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        display: false,
      },
    },
  };

  // const getAnalyticsReportAPIcall = () => {
  //   let data = {
  //     _id: localdata?._id,
  //     C_id: Cname,
  //   };
  //   let token = authtoken;
  //   getAnalyticsReportAPI(data,token).then((res)=>{
  //     if(res.flag){
  //       console.log("REPORT success res ===> ",res)
  //     }else{
  //       console.log("REPORT failure res ===> ",res)
  //     }
  //   })
  // }

  const getOverallAnalyticsAPICall = async () => {
    let data = {
      _id: localdata?._id,
    };
    let token = authtoken;
    let response = await getOverallAnalyticsAPI(data, token);
    // if(response){
    //   setDisable(false);
    // }
    let url;
    const blob = await response.blob();
    url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'overall_analytics.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const getSingleAnalyticsAPICall = async () => {
    let data = {
      // id: campaignName,
      id: Cname,
      email: email,
    };
    let token = authtoken;
    // let response = await getSingleAnalyticsAPI(data, token);
    // let url;
    // const blob = await response.blob();
    // url = window.URL.createObjectURL(new Blob([blob]));
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', 'campagin_analytics.csv');
    // document.body.appendChild(link);
    // link.click();
    // link.parentNode.removeChild(link);
    getSingleAnalyticsAPI(data, token).then((res) => {
      if (res?.flag) {
        console.log('sent');
      } else {
        toast.error('Error in sending report', res?.message);
      }
    });
  };

  let hyperlinks = [];
  if (
    campaignDetails?.email_stats?.hyperlinks &&
    campaignDetails?.email_stats?.hyperlinks.length > 0
  ) {
    hyperlinks = [
      'All Links',
      ...campaignDetails?.email_stats?.hyperlinks.map((link) => link?.url),
    ];
  }

  useEffect(() => {
    if (hyperlinks && hyperlinks.length > 0) {
      setSelectedItem(hyperlinks[0]);
    }
  }, []);

  const hyperlinkfunction = async (e) => {
    if (e === 'All Links') {
      setSelectedItem('All Links');
      setLinkIndex(-1);
      setNlinkCTR(
        campaignDetails?.email_stats?.link_Clicks === 0
          ? '0'
          : campaignDetails?.email_stats?.link_Clicks
      );
      setGlinkCTR(
        campaignDetails?.email_stats?.link_CTR === 0
          ? '0'
          : campaignDetails?.email_stats?.link_CTR * 100
      );
    } else {
      const hyperlinkIndex = await campaignDetails?.email_stats?.hyperlinks.findIndex(
        (hyperlink) => hyperlink?.url === e
      );

      setLinkIndex(hyperlinkIndex);
      setSelectedItem(campaignDetails?.email_stats?.hyperlinks[hyperlinkIndex]);
      setNlinkCTR(
        campaignDetails?.email_stats?.hyperlinks[hyperlinkIndex]?.clickRecord === 0
          ? '0'
          : campaignDetails?.email_stats?.hyperlinks[hyperlinkIndex]?.clickRecord
      );
      setGlinkCTR(
        campaignDetails?.email_stats?.hyperlinks[hyperlinkIndex]?.link_CTR === 0
          ? '0'
          : campaignDetails?.email_stats?.hyperlinks[hyperlinkIndex]?.link_CTR * 100
      );
    }
  };

  useEffect(() => {
    userSpecificApiCall();
  }, [linkIndex]);

  const DropdownLinks = ({ items, hyperlinkfunction }) => {
    const handleChange = (event) => {
      const newValue = event.target.value;
      // setSelectedItem(newValue);
      hyperlinkfunction(newValue);
    };

    return (
      <div style={{ marginTop: '10px' }}>
        <select
          value={selectedItem?.url}
          onChange={handleChange}
          style={{ padding: '10px', fontSize: '16px', borderRadius: '8px', color: colors.linkblue }}
        >
          {items.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const downloadCsvFile = async () => {
    try {
      const data = {
        campaignId: Cname,
        channel: channel,
      };
      let token = authtoken;
      const response = await downloadUserSpecificCsv(data, token);
      if (!response || !(response instanceof Blob)) {
        console.error('Invalid response received from API.');
        return;
      }
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'user_specific_analytics.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV file:', error);
    }
  };

  const downloadCsvFileforsingle = async () => {
    try {
      const data = {
        id: Cname,
        email: email
      };
      const token = authtoken;

      const response = await downloadSingleAnalyticsCsv(data, token);

      if (!response || !(response instanceof Blob)) {
        console.error('Invalid response received from API.');
        return;
      }
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'single_analytics.csv');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading CSV file:', error);
    }
  };
  // const campaignOptions = campaignList
  //   ?.filter((e) => {
  //     return (
  //       e?.announcement?.label_name !== 'shopify_order_cancel' &&
  //       e?.announcement?.label_name !== 'shopify_order_confirm' &&
  //       e?.announcement?.label_name !== 'shopify_order_fulfill' &&
  //       e?.announcement?.label_name !== 'shopify_order_feedback' &&
  //       e?.announcement?.label_name !== 'shopify_abandon_cart' &&
  //       e?.announcement?.label_name !== 'woocommerce_abandoned_cart'
  //     );
  //   })
  //   .map((e) => ({
  //     value: e._id,
  //     label:
  //       e.announcement.label_name === 'customer_enquiry'
  //         ? `Follow up with discount (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //         : e.announcement.label_name === 'renewal_remainder' ||
  //           e.announcement.label_name === 'renewal_reminder'
  //           ? `Send a Reminder (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //           : e.announcement.label_name === 'share_info'
  //             ? `Share info/update/quote (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //             : e.announcement.label_name === 'thank_you_customer'
  //               ? `Thank your customer (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //               : e.announcement.label_name === 'additional_discount_offer'
  //                 ? `Additional discount offer (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                 : e.announcement.label_name === 'repeatcustomer_offer' ||
  //                   e.announcement.label_name === 'repeatcustomer_offer2'
  //                   ? `Repeat customer offer (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                   : e.announcement.label_name === 'customer_win_back'
  //                     ? `Customer win back (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                     : e.announcement.label_name === 'business_info_newsletter'
  //                       ? `Business info newsletter (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                       : e.announcement.label_name === 'welcome_insta_message_2'
  //                         ? `Welcome Instagram (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                         : e.announcement.label_name === 'loyal_customers_new'
  //                           ? `X% off with discount code (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                           : e.announcement.label_name === 'discount_all_products_new'
  //                             ? `Thank you message (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                             : e.announcement.label_name === 'CustomEmail'
  //                               ? `Custom HTML (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                               : e.announcement.label_name === 'maintainance_update'
  //                                 ? `Maintenance update (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                 : e.announcement.label_name === 'new_product_service_marathi'
  //                                   ? `New product service Marathi (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                   : e.announcement.label_name === 'reply_multiple'
  //                                     ? `Share awareness multiple replies (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                     : e.announcement.label_name === 'do_you_know'
  //                                       ? `Do you know (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                       : e.announcement.label_name === 'Course'
  //                                         ? `Sell a ${e.announcement.label_name} (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                         : e.announcement.label_name === 'Product'
  //                                           ? `Sell a ${e.announcement.label_name} (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                           : e.announcement.label_name === 'Offer'
  //                                             ? `Share an ${e.announcement.label_name} (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`
  //                                             : `${e.announcement.label_name} (${getLabelDate(e.announcement.date)}, ${e.announcement.template_name})`,
  //   }));
  const getUserSpecificReport = async () => {
    let data = {
      // campaign_id: campaignName,
      campaign_id: Cname,
      // channel: channelName,
      channel: channel,
    };
    let token = authtoken;
    let response = await getUserSpecificReportAPI(data, token);
    let url;
    const blob = await response.blob();
    url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'user_specific_analytics.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  return (
    <div
      className="analytics_container"
      onClick={() => {
        setShowFilter(false);
      }}
    // className="d-flex flex-column justify-content-between align-items-center w-100 h-100"
    // style={{ paddingInline: 40, paddingBlock: 20 }}
    >
      <div className="d-flex flex-row justify-content-between align-items-center w-100 cursordefault">
        <h3
          style={{
            // padding: 0,
            // margin: 0,
            marginBlock: 8,
            // fontSize: 24,
            // fontWeight: 700,
            color: colors.black,
          }}
        >
          {'Analytics'}
        </h3>
        {!excepted && (
          <CheerioButton
            borderStyle={{ border: "none" }}
            textStyle={{ color: colors.white }}
            btnText={'Export Report'}
            backColor={colors.darkPurple}
            onclick={() => {
              //   getOverallAnalyticsAPICall(userID);
              setUserSpecificCsv(false);
              setShowMailCsv(true);
            }}
            icon={images.ANExport}
          />
        )}
      </div>
      {showMailCsv ? (
        templateName && !userSpecificCsv ? (
          <MailCsvModal
            show={showMailCsv}
            setShow={setShowMailCsv}
            templateName={templateName}
            selectedNumberOfDate={numberOfDate}
            date={date}
          ></MailCsvModal>
        ) : !templateName && !userSpecificCsv ? (
          <MailCsvModal
            show={showMailCsv}
            setShow={setShowMailCsv}
            // campaign_id={campaignName}
            campaign_id={Cname}
            // channel={channelName}
            channel={channel}
          ></MailCsvModal>
        ) : (
          <MailCsvModal
            show={showMailCsv}
            setShow={setShowMailCsv}
            // campaign_id={campaignName}
            campaign_id={Cname}
            // channel={channelName}
            channel={channel}
            userSpecific={userSpecificCsv}
            setUserSpecificCsv={setUserSpecificCsv}
          ></MailCsvModal>
        )
      ) : (
        ''
      )}

      {showMailCsvModal && (
        <MailRepliesModal
          show={showMailCsvModal}
          setShow={setShowMailCsvModal}
          email={email}
          setEmail={setEmail}
          mailApiCall={getSingleAnalyticsAPICall}
        ></MailRepliesModal>
      )}
      <div
        className="d-flex flex-column justify-content-start align-items-center w-100 verythinscrollnew"
        style={{ overflowY: 'scroll', flex: 1 }}
      >
        {/* -----x-----x----- */}
        {/* -----x------x----- */}
        {/* Feature info */}
        {feature && (
          <div
            className="row"
            style={{
              borderRadius: '10px',
              width: '100%',
              padding: '10px',
              marginBlock: 8,
              backgroundColor: '#ECE1FF',
            }}
          >
            <div
              className="col-9 d-flex justify-content-end"
              style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
            >
              <img src={images.Announce}></img> Now you can retarget users from past campaign by
              clicking on “<img src={images.ThreeDot} style={{ width: '20px' }}></img>”
            </div>
            <div className="col d-flex justify-content-end" style={{ marginLeft: '20px' }}>
              <img
                onClick={() => showFeature(false)}
                src={images.CMClose}
                style={{ width: '20px' }}
              ></img>
            </div>
          </div>
        )}
        {/* Modal for New Campaign */}
        {openANModal && (
          <AnnNewModal
            setOpenANModal={setOpenANModal}
            setName={retargetingCampaignName}
            reTargetingLabelName={retargetingLabelName}
          />
        )}

        {/* Chart Component */}
        <div
          className="d-flex flex-column justify-content-start align-items-start"
          style={{
            width: '100%',
            backgroundColor: colors.white,
            border: `1px solid ${colors.borderwhite}`,
            borderRadius: 16,
            marginBlock: 16,
            padding: 16,
            position: 'relative',
          }}
        >
          {/* Header */}
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{}}
          // className='cp_header_container'
          // className="d-flex flex-row justify-content-between align-items-center w-100"
          // style={{ paddingInline: 16}}
          >
            <div className="cp_header_container__title">
              {/* <div className="d-flex justify-content"> */}{' '}
              <h3
                style={{
                  // padding: 0,
                  // margin: 0,
                  // fontSize: 20,
                  // fontWeight: 700,
                  color: colors.black,
                }}
              >
                {'Campaign Performance'}
              </h3>{' '}
              <img
                src={images.Refresh}
                style={{
                  height: 20,
                  width: 20,
                  objectFit: 'contain',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  campaignDetailsApiCall();
                  eventsend("Reload_button_Analytics_page_clicked");
                }}
              ></img>
            </div>
            <div
              className="d-flex flex-row justify-content-between align-items-center"
              style={{ width: '100%', maxWidth: '675px' }} 
            >

              <InputGroup
                className="btncustom"
                style={{
                  width: mobileWidth ? '100%' : '60%',
                  marginBlock: 6,
                  marginInlineEnd: mobileWidth ? 0 : 8,
                  height: '100%',
                }}
              >
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
                >
                  {'CAMPAIGN'}
                </InputGroup.Text>

                <Select
                  options={campaignOptions}
                  className="btncustom"
                  placeholder="Select or Search Campaign"
                  value={campaignOptions?.find((option) => option.value === Cname)}
                  onChange={(selectedOption) => {
                    eventsend("Select_campaign_drop_down_Analytics_page_clicked");
                    setCname(selectedOption.value);
                    showFeature(true);
                    window.history.pushState({}, '', `/creator/analytics?campaign=${selectedOption.value}`);
                  }}
                  isSearchable={true}
                  styles={{
                    control: (base) => ({
                      ...base,
                      fontSize: 12,
                      fontWeight: 600,
                      color: colors.greys04,
                      cursor: 'pointer',
                      width: '250px',
                    }),
                    menu: (base) => ({
                      ...base,
                      width: '250px'
                    }),
                  }}
                  onMenuScrollToBottom={loadMoreOptions}
                  onInputChange={(inputValue) => setSearchTerm(inputValue)}
                  isLoading={loading}
                />

              </InputGroup>

              {/* <InputGroup className="btncustom" style={{ marginRight: '10px', height: '100%' }}>
              </InputGroup> */}
              <InputGroup
                className="btncustom"
                style={{
                  width: mobileWidth ? '100%' : '40%',
                  marginInline: mobileWidth ? 0 : 8,
                  marginBlock: 6,
                  height: '100%',
                }}
              >
                <InputGroup.Text
                  id="basic-addon1"
                  style={{ fontSize: 12, fontWeight: 600, color: colors.black, cursor: 'default' }}
                >
                  {'CHANNEL'}
                </InputGroup.Text>
                <Form.Select
                  className="btncustom"
                  aria-label="Default select example"
                  style={{
                    fontSize: 12,
                    fontWeight: 600,
                    color: colors.greys04,
                    cursor: 'pointer',
                  }}
                  value={channel}
                  onChange={(e) => {
                    eventsend("Select_channel_drop_down_Analytics_page_clicked");
                    setChannel(e.target.value);
                    // setChannelName(e.target.value);
                  }}
                >
                  <option value={'WhatsApp'} key={'1'}>
                    WhatsApp
                  </option>
                  <option value={'Email'} key={'2'}>
                    Email
                  </option>
                  <option value={'SMS'} key={'3'}>
                    SMS
                  </option>
                </Form.Select>
              </InputGroup>

              <div
                className="d-flex justify-content-between align-items-center"
                style={{
                  border: showFilter ? '1px solid #666666' : `1px solid ${colors.borderwhite}`,
                  // width: '40%',
                  paddingInline: 8,
                  // paddingBlock: 8,
                  borderRadius: '6px',
                  height: '32px',
                  cursor: 'pointer',
                  marginInlineStart: 8,
                  // marginTop: '6px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilter(!showFilter);
                }}
              >
                {/* <div className="d-flex"> */}
                <img
                  height={14}
                  src={showFilter ? images.FilledFilter : images.Filter}
                  style={{ marginTop: '2px' }}
                ></img>
                {/* <p style={{ fontSize: '12px', fontWeight: '600', marginLeft: '10px' }}>FILTER</p> */}
                {/* </div> */}
                {/* <img height={'20px'} src={images.Down}></img> */}
              </div>
              {/* <Tip title={'Download Report'}> */}
              {!excepted && (
                <Tip
                  title={'Download Report'}
                  className="d-flex flex-row justify-content-start align-items-center"
                >
                  <h3 className="d-flex d-md-none" style={{ color: '#000' }}>
                    {'Download Report'}
                  </h3>
                  <img
                    src={images.Download_CSV}
                    alt="download_img"
                    style={{
                      height: 28,
                      width: 25,
                      objectFit: 'contain',
                      backgroundColor: '#F0F2F2',
                      color: '#262626',
                      marginLeft: '15px',
                      border: '1px solid #E6E6E6',
                      borderRadius: '2px',
                      opacity: Cname || templateName ? '100%' : '40%',
                      cursor: Cname || templateName ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      if (Cname) {
                        if (totalCampaignSent < 15000) {
                          downloadCsvFileforsingle(Cname);
                        }
                        else if (totalCampaignSent > 15000 && Cname) {
                          setShowMailCsvModal(true);
                        }
                      } else if (templateName) {
                        setShowMailCsv(true);
                      }
                    }
                    }

                  />
                </Tip>
              )}
            </div>
          </div>

          {showFilter && (
            <FilterMenu
              setCampaignDetails={setCampaignDetails}
              setShowFilters={setShowFilter}
              setTemplateName={setTemplateName}
              templateName={templateName}
              Cname={Cname}
              setCname={setCname}
              setUserAnalytics={setUserAnalytics}
              // setCampaignName={setCampaignName}
              setCampaignName={setCname}
              campaignList={campaignList}
              showFeature={showFeature}
              date={date}
              setDate={setDate}
              numberOfDate={numberOfDate}
              setNumberOfDate={setNumberOfDate}
              setGraphLoading={setgraphloading}
              setRows={setRows}
              setAnalyticsLoading={setAnalyticsLoading}
              page={page}
              setPaginationData={setPaginationData}
            ></FilterMenu>
          )}

          {!isPremiumUser && !Cname && (
            <div
              className="d-flex flex-row"
              style={{
                width: '100%',
                padding: '10px',
                margin: '10px',
                backgroundColor: colors.primary01,
                textAlign: 'center',
                justifyContent: 'center',
                borderRadius: 10,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.greys03,
                gap: '2px',
              }}
            >
              <img
                src={images.InfoCircle}
                alt="Campaign information"
                style={{
                  height: 22,
                  width: 24,
                  objectFit: 'contain',
                }}
              />
              <p style={{ fontWeight: 500 }}>
                {
                  'Campaign performance shown here contains dummy values only. This is how your campaign performance will look like'
                }
              </p>
            </div>
          )}
          {/* ------x--------x------- */}
          {graphloading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: 300 }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : nullValues ? (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 150,
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.black04,
                }}
              >
                {'Not enough data to show right now. Please check back later.'}
              </p>
            </>
          ) : showAna ? (
            details ? (
              <>
                {channel === 'Email' && hyperlinks && hyperlinks.length > 0 && (
                  <div>
                    <DropdownLinks items={hyperlinks} hyperlinkfunction={hyperlinkfunction} />
                  </div>
                )}
                <div className="analytics_details_container">
                  <div
                    className="stats_card_container"
                  // className="d-flex flex-row justify-content-between align-items-start w-100"
                  // style={{ paddingInline: 16, marginBlockStart: 16 }}
                  >
                    <CampaignStatsCard text={'Sent'} value={gsent} textnumber={nsent} />
                    <CampaignStatsCard
                      text={'Delivered'}
                      value={gdelivered}
                      textnumber={ndelivered}
                    />
                    <CampaignStatsCard text={'Opened'} value={gopened} textnumber={nopened} />
                    <CampaignStatsCard text={'Clicks'} value={glinkCTR} textnumber={nlinkCTR} />
                  </div>
                  <div className="line_graph_container">
                    {/* <div className="" style={{ height: 400, width: '100%', paddingBlockStart: 16 ,transform:'rotate(90deg)' }}> */}
                    <Line options={options} data={data} />
                  </div>
                </div>
              </>
            ) : (
              <div
                style={{
                  padding: 0,
                  marginLeft: '30%',
                  marginBlock: 150,
                  fontSize: 16,
                }}
              >
                <p style={{ textAlign: 'center', color: 'black', fontWeight: '700' }}>
                  {'No data available'}
                </p>

                <p style={{ marginTop: '10px' }}>
                  {'Selected channel was not used for this campaign.'}
                </p>
              </div>
            )
          ) : (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 150,
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.black04,
                }}
              >
                {'Select a Campaign to view Analytics'}
              </p>
            </>
          )}
        </div>

        {/* Individual analytics  */}
        <div className="detailed_analytics_container">
          {/* Header */}
          <div
            className="d-flex flex-row justify-content-between align-items-center w-100"
            style={{ paddingInline: 16 }}
          >
            <div className="d-flex justify-content">
              <h3 style={{ color: colors.black }}>{'Detailed User analytics'}</h3>{' '}
              <img
                src={images.Refresh}
                style={{
                  height: 20,
                  width: 20,
                  objectFit: 'contain',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => userSpecificApiCall()}
              ></img>
            </div>
            <div
              className="d-flex flex-row justify-content-end align-items-center"
              style={{ width: '50%' }}
            >
              {!excepted && (
                <Tip title={'Download Report'}>
                  <img
                    src={images.Download_CSV}
                    alt="download_img"
                    style={{
                      height: 28,
                      width: 25,
                      objectFit: 'contain',
                      marginInlineEnd: 8,
                      backgroundColor: '#F0F2F2',
                      color: '#262626',
                      marginLeft: '15px',
                      border: '1px solid #E6E6E6',
                      borderRadius: '2px',
                      // opacity: campaignName ? '100%' : '40%',
                      opacity: Cname ? '100%' : '40%',
                      cursor: Cname ? 'pointer' : 'default',
                      // cursor: campaignName ? 'pointer' : 'default',
                    }}
                    onClick={() => {
                      // if (campaignName) {
                      if (Cname) {
                        if (userSpecificAnalytics < 15000) {
                          downloadCsvFile(Cname)
                        }
                        else {
                          setUserSpecificCsv(true);
                          setShowMailCsv(true);
                        }
                      }

                    }}
                  />
                </Tip>
              )}
            </div>
          </div>
          {/* ------x--------x------- */}
          {analyticsLoading ? (
            <>
              <div
                className="d-flex flex-column justify-content-center align-items-center w-100"
                style={{ height: 300 }}
              >
                <SpinnerLoader />
              </div>
            </>
          ) : userAnalytics && userAnalytics.length ? (
            <>
              <div style={{ width: '100%', flex: 1, marginBlock: 16 }}>
                <TableContainer sx={{ flex: 1 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                              width: column.width,
                              fontSize: 16,
                              fontWeight: 500,
                              color: colors.black,
                              backgroundColor: colors.white04,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        // .slice(page-1 * rowsPerPage, page-1 * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              {columns.map((column) => {
                                const value = row[column.id];
                                const failedValue = row['failed'];
                                const failedMessage = row['failedReason'];
                                return (
                                  <TableCell key={column.id} align={column.align}>
                                    {column.id === 'deliveryAt' && !value && failedValue ? (
                                      <>
                                        <Tip
                                          title={
                                            failedMessage
                                              ? failedMessage
                                              : channel === 'SMS'
                                                ? 'Telemarketer Deliverability Failure'
                                                : channel === 'Email'
                                                  ? 'SMTP deliverability failure'
                                                  : 'Meta deliverability failure'
                                          }
                                        >
                                          <div className="d-flex flex-row justify-content-center align-items-center w-100">
                                            <img
                                              src={images.ErrorImg}
                                              style={{
                                                height: 20,
                                                width: 20,
                                                objectFit: 'contain',
                                              }}
                                            ></img>
                                            <p style={{ color: colors.error03, fontSize: 14, textDecoration: 'underline' }}>
                                              {'Failed'}
                                            </p>
                                          </div>
                                        </Tip>
                                      </>
                                    ) : column.format ? (
                                      // {column.format && typeof value === 'number'
                                      <p style={{ color: colors.black, fontSize: 14 }}>
                                        {column.format(value)}
                                      </p>
                                    ) : (
                                      <p style={{ color: colors.black, fontSize: 14 }}>{value}</p>
                                    )}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Pagination
                  count={paginationData?.pages}
                  page={page}
                  onChange={handleChangePage}
                  shape="rounded"
                />
              </div>
            </>
          ) : (
            <>
              <p
                style={{
                  padding: 0,
                  margin: 0,
                  marginBlock: 150,
                  fontSize: 16,
                  fontWeight: 600,
                  color: colors.black04,
                }}
              >
                {/* {campaignName && !analyticsLoading */}
                {Cname && !analyticsLoading
                  ? 'No results found'
                  : !userAnalytics
                    ? 'No data available'
                    : 'Select a Campaign to view Analytics'}
              </p>
            </>
          )}
        </div>

        {/* -----x-------x------ */}
      </div>
    </div>
  );
};

export default CreatorAnalyticsNew;
