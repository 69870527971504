import React, { useEffect, useState } from 'react'
import CheerioButton from '../../Components/CheerioButton'
import colors from '../../Utils/colors'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Tooltip } from '@mui/material';
import images from '../../Utils/images';
import { useSelector } from 'react-redux';
import { getAllExcelLabels } from '../../Services';
import { toast } from 'react-toastify';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import LabelPage from './Components/LabelPage';
import { ContactMapping } from '../ContactModule/ContactMapping/ContactMapping';
import ExcelAnnouncementModal from './Components/ExcelAnnouncementModal';

const ExcelUpload = () => {
    const authtoken = useSelector((state) => state.main.auth_token);
    const navigate = useNavigate();
    const location = useLocation();
    const [rowsData, setRowsData] = useState([])
    const [isAnnModalOpen, setIsAnnModalOpen] = useState(false);
    const [selectedLabel, setSelectedLabel] = useState(null);

    const { retrigger } = location.state || false;

    useEffect(()=>{
        getAllExcelLabelsAPIcall();
    },[])

    useEffect(()=>{
        if(retrigger){
            getAllExcelLabelsAPIcall();
        }
    },[retrigger])

    const getAllExcelLabelsAPIcall = () => {
        let token = authtoken;
        getAllExcelLabels(token).then((res)=>{
            if(res?.flag){
                setRowsData(res?.data);
            }else{
                toast.error(res?.message,{autoClose:true,position:'top-right'})
            }
        })
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const columns = [
        { id: 'listName', label: 'List Name', width: '40%', align: 'start' },
        { id: 'addedOn', label: 'Added On', width: '35%', align: 'center' },
        // { id: 'contacts', label: 'Contacts', width: '25%', align: 'center' },
        { id: 'action', label: 'Action', width: '25%', align: 'center' },
    ];

    const uploadAction = () => {
        navigate('/creator/excelLabel/upload');
    }

  return (
    <>
        {isAnnModalOpen && 
            <ExcelAnnouncementModal 
                setIsModalOpen={setIsAnnModalOpen} 
                labelData={selectedLabel}
            />
        }
        {location.pathname === '/creator/excelLabel' && 
        <>
        <div className='w-100 h-100 Column justify-content-start' style={{
            paddingBlock:32,
            paddingInline:40,
            overflow:'hidden'
        }}>
            <div className='Row w-100'>
                <h3>
                    {'Excel Labels'}
                </h3>
                <CheerioButton 
                    borderStyle={{borderColor: colors.primary03}}
                    backColor={colors.primary03}
                    textStyle={{fontSize:16,fontWeight:600,color:colors.white01}}
                    btnText={'Upload excel'}
                    onclick={()=>{
                        uploadAction();
                    }}
                />
            </div>
            <TableContainer sx={{ flex: 1 , marginBlock:'20px', overflowY:'auto'}}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => {
                                return (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            width: column.width,
                                            fontSize: 16,
                                            fontWeight: 500,
                                            color: colors.black,
                                            backgroundColor: colors.white04,
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsData?.length > 0 && rowsData.map((row) => {
                            return (
                                <>
                                <TableRow hover role="checkbox" tabIndex={-1} key={row._id}>
                                    {columns.map((column) => {
                                        let value = '';
                                        if (column.id == 'listName') {
                                            value = row?.listName;
                                        } else if (column.id == 'addedOn') {
                                            value = formatDate(row?.createdAt) ?? 'NA';
                                        } else if (column.id == 'contacts') {
                                            value = row?.contactCount ?? '0'
                                        }
                                        return (
                                            <TableCell key={column.id} align={column.align}>
                                                {column.id == 'action' ? (
                                                    <>
                                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                                        <Tooltip title={'View'}>
                                                            <img
                                                                src={images.EUViewBlue}
                                                                style={{
                                                                    marginInline: 6,
                                                                    height: 18,
                                                                    width: 18,
                                                                    objectFit: 'contain',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedLabel(row)
                                                                    navigate(`/creator/excelLabel/label/${row._id}`)
                                                                    // onclickEditAction(row?._id);
                                                                }}
                                                            ></img>
                                                        </Tooltip>
                                                        {/* <Tooltip title={'Delete'}>
                                                            <img
                                                                src={images.EUDeleteBlue}
                                                                style={{
                                                                    marginInline: 6,
                                                                    height: 18,
                                                                    width: 18,
                                                                    objectFit: 'contain',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    // onclickDuplicateAction(row?._id);
                                                                }}
                                                            ></img>
                                                        </Tooltip> */}
                                                        <Tooltip title={'Announcement'}>
                                                            <img
                                                                src={images.EUAnnounceBlue}
                                                                style={{
                                                                    marginInline: 6,
                                                                    height: 18,
                                                                    width: 18,
                                                                    objectFit: 'contain',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    setSelectedLabel(row);
                                                                    setIsAnnModalOpen(true);
                                                                    // onclickDeleteAction(row?._id);
                                                                }}
                                                            ></img>
                                                        </Tooltip>
                                                        {/* <Tooltip title={'Download'}>
                                                            <img
                                                                src={images.EUDownloadBlue}
                                                                style={{
                                                                    marginInline: 6,
                                                                    height: 18,
                                                                    width: 18,
                                                                    objectFit: 'contain',
                                                                    cursor: 'pointer',
                                                                }}
                                                                onClick={() => {
                                                                    // downloadFormResponsesApiCall(row?._id);
                                                                }}
                                                            ></img>
                                                        </Tooltip> */}
                                                    </div>
                                                    </>
                                                ) : (
                                                    <p style={{ fontSize: 14, color: colors.black }}>
                                                        {value}
                                                    </p>
                                                )}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                                </>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        </>
        }
        <Routes>
            <Route path='/label/:id' element={<LabelPage selectedLabel={selectedLabel}/>}/>
            <Route path='/upload' element={<ContactMapping excelLabel={true}/>}/>
        </Routes>
    </>
  )
}

export default ExcelUpload