import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { getAllEmbeddedForms } from '../../../Services';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const FormModal = ({ data, setData, btnDisabled, setBtnDisabled }) => {

    const [selectedForm, setSelectedForm] = useState();
    const authtoken = useSelector((state) => state.main.auth_token);
    const [rows, setRows] = useState([]);

    useEffect(()=>{
        getEmbeddedForms(0, 15);
    },[])

    const getEmbeddedForms = (page, limit) => {
        let token = authtoken;
        getAllEmbeddedForms(token, page+1, limit).then((res)=>{
            if(res?.flag){
                setRows(res?.data?.docs)
                console.log("success get ===> ",res);
            }else{
                console.log("failure get ===> ",res)
            }
        })
    }

    useEffect(()=>{
        if(data?.formId){
            setSelectedForm(data?.formId)
        }
    },[data])

  return (
    <>
    <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
                {'Select form'}
            </Form.Label>
            <Form.Select
                type="text"
                placeholder="select"
                className="w-100 btncustom dropdown"
                style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                value={selectedForm}
                onChange={(e) => {
                    const selectedOption = e.target.selectedOptions[0];
                    const value = selectedOption.value;
                    const formName = selectedOption.getAttribute('formname');
                    setSelectedForm(value);
                    setBtnDisabled(false);
                    setData({ 
                        ...data, 
                        formId: value,
                        formName: formName,
                    });
                }}
            >
                    <option
                        style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                        key="blankchoice"
                        hidden
                        value
                    >
                        {'Select form'}
                    </option>
                    {rows &&
                        rows
                            // .filter((item, index) => {
                            //   return item?.status === 'APPROVED';
                            // })
                            .map((item, index) => {
                                return (
                                    <option
                                        style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }}
                                        value={item._id}
                                        formname={item.formName}
                                    >
                                        {item.formName}
                                    </option>
                                );
                            })}
                </Form.Select>
            </InputGroup>
        </div>
    </>
  )
}

export default FormModal