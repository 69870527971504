import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { V2EmailBuilderWorkflowList } from '../../../Services';
import { Parser } from 'html-to-react';
import images from '../../../Utils/images';
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Tooltip } from '@mui/material';

const SendEmailModal = ({ data, setData }) => {

    const [emailMode, setEmailMode] = useState('Email Builder');
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const [subject, setSubject] = useState('');

    const [templateList, setTemplateList] = useState([]);
    const [HTMLFile, setHTMLFile] = useState();
    const [rhtml, setrhtml] = useState();

    const authtoken = useSelector((state) => state.main.auth_token);
    const htmlref = useRef();
    const [skipReply, setSkipReply] = useState(false);

    useEffect(() => {
        if (data?.emailMode) {
            setEmailMode(data.emailMode);
        }
        if (data?.selectedTemplate) {
            setSelectedTemplate(data.selectedTemplate);
        }
        if (data?.HTMLFile) {
            setHTMLFile(data.HTMLFile);

        }
        if (data?.subject) {
            setSubject(data.subject);
        }
        if (data?.rhtml) {
            setrhtml(data.rhtml);
        }
        if (data?.skip) {
            setSkipReply(data?.skip)
        }
    }, [])

    useEffect(() => {
        setData({
            ...data,
            emailMode: emailMode,
            selectedTemplate: selectedTemplate,
            selectedTemplateName: templateList.filter((e) => e._id === selectedTemplate)[0]?.emailName,
            subject: subject,
            HTMLFile: HTMLFile,
            rhtml: rhtml,
            skip: skipReply
        })

    }, [emailMode, selectedTemplate, subject, HTMLFile, rhtml, skipReply])

    useEffect(() => {
        if (emailMode === 'Email Builder') {
            getDesignAPIcall();
        }
    }, [emailMode])

    const getDesignAPIcall = () => {
        let token = authtoken;
        V2EmailBuilderWorkflowList(token).then((res) => {
            if (res) {
                console.log('success response ===> ', res);
                setTemplateList(res?.data);
            } else {
                console.log('error response ===> ', res);
            }
            // setTimeout(() => {
            //     setIsLoading(false);
            // }, 500);
        });
    };

    const handlehtmlupload = async (e) => {
        const file = e.target.files[0];
        setHTMLFile(file);
        const readhtml = await convertToRead(file);
        setrhtml(readhtml);
    };

    const convertToRead = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsText(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return (
        <>
            <div className='w-100 h-100 flex-column justify-content-start align-items-start'>


                <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 16, fontWeight: 600, color: colors.black, width: '100%' }}>
                    {'Subject'}
                </p>
                <InputGroup className="mb-3" style={{ width: '100%', marginBlockEnd: 16 }}>
                    <Form.Control type='text' placeholder={'Enter subject here'} className='w-100 btncustom'
                        style={{ borderRadius: 8, fontSize: 14, fontWeight: 400, color: colors.black }}
                        // isInvalid={(renewalText.length >= 640)}
                        maxLength={640}
                        value={subject}
                        onChange={(e) => { setSubject(e.target.value) }}
                    />
                </InputGroup>


                <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                    <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>{'Email Mode'}</Form.Label>
                    <Form.Select type='text' placeholder="select" className='w-100 btncustom dropdown'
                        style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                        value={emailMode}
                        onChange={(e) => { setEmailMode(e.target.value) }}
                    >
                        <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'Email Builder'} key={'Email Builder'}>
                            {'Email Builder'}
                        </option>
                        <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={'HTML Upload'} key={'HTML Upload'}>
                            {'HTML Upload'}
                        </option>
                    </Form.Select>
                </InputGroup>


                {emailMode === 'Email Builder' ? (
                    <>
                        <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 16 }}>
                            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>{'Select Template'}</Form.Label>
                            <Form.Select type='text' placeholder="select" className='w-100 btncustom dropdown'
                                style={{ borderRadius: 8, fontSize: 16, fontWeight: 400, color: colors.greys01 }}
                                value={selectedTemplate}
                                onChange={(e) => { console.log("444444444 ---- ", e.target.value); setSelectedTemplate(e.target.value) }}
                            >
                                <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} key="blankchoice" hidden value>
                                    {'Choose template'}
                                </option>
                                {templateList && templateList.map((item, index) => {
                                    return (
                                        <option style={{ width: '100%', marginTop: 4, marginBottom: 4, borderRadius: 8 }} value={item._id} key={`EB${item?._id}`}>
                                            {item?.emailName}
                                        </option>
                                    )
                                })}
                            </Form.Select>
                        </InputGroup>
                    </>
                ) : emailMode === 'HTML Upload' && (
                    <>
                        <div className='d-flex flex-column justify-content-center align-items-center w-100 my-2'
                            style={{ border: `1px solid ${colors.borderwhite}`, borderRadius: 8, paddingBlock: 20, minHeight: 200, cursor: 'pointer' }}
                            onClick={() => {
                                if (!(HTMLFile || rhtml)) {
                                    htmlref.current.click();
                                }
                            }}>
                            {rhtml && (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: rhtml }}></div>
                                </>
                            )}
                            <div className='d-flex flex-row justify-content-center align-items-center w-75'>
                                <p style={{ padding: 0, margin: 0, marginBlock: 4, fontSize: 16, fontWeight: 600, color: colors.greys04, paddingBlock: 20 }}>
                                    {HTMLFile ? HTMLFile.name : rhtml ? 'Remove Uploaded HTML' : 'Upload HTML here'}
                                </p>
                                {HTMLFile || rhtml &&
                                    <img src={images.Cross}
                                        style={{ height: 18, width: 18, marginInlineStart: 24, cursor: 'pointer' }}
                                        onClick={(e) => {
                                            // e.stopPropagation();
                                            setHTMLFile(null);
                                            setrhtml(null);
                                        }}
                                    />
                                }
                            </div>

                        </div>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            ref={htmlref}
                            accept=".html"
                            value=""
                            onChange={(e) => {
                                console.log('--------input ref onchange------');
                                handlehtmlupload(e);
                            }}
                        />
                    </>
                )}

            </div>
            <div className='d-flex flex-row justify-content-between align-items-center w-100 mb-4 mt-2'>
                <Form.Check
                    // disabled={disabled}
                    type='checkbox'
                    className='btncustom'
                    // value={skipReply ? true : false}
                    checked={skipReply}
                    label='Skip user reply'
                    onChange={() => { setSkipReply(!skipReply) }}
                />
                <Tooltip title={'Next node will trigger itself even if recipient does not reply'} placement='bottom-end'>
                    <div>
                        <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
                    </div>

                </Tooltip>
            </div>
        </>
    )
}

export default SendEmailModal