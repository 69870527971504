import React from 'react'
import images from '../../../Utils/images'
import CheerioButton from '../../../Components/CheerioButton'
import colors from '../../../Utils/colors'
import { useNavigate } from 'react-router-dom'

const AddContacts = ({ excelLabel }) => {
    const navigate = useNavigate()
    return (
        <div style={{ padding: "1% 2%", width: '91%', display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: 10
            }}>
                <img src={images.AddContactImage} alt="" height={200} />
                <p style={{ fontWeight: 700, fontSize: 20 }}>{"We’re adding your contacts"}</p>
                <p style={{
                    textAlign: "center",
                    marginBottom: `5%`
                }}>
                    {`Depending on the number of contacts you've uploaded, this may take several `}
                    <br />
                    {`minutes. You can move on to your next tasks in the meantime.`}
                </p>
                <CheerioButton
                    borderStyle={{ border: "none", width: '25%' }}
                    btnText={"Done"}
                    backColor={colors.darkPurple}
                    textStyle={{ color: colors.white }}
                    onclick={() => {
                        if(excelLabel){
                            navigate("/creator/excelLabel",{state: {retrigger: true}})
                        }else{
                            navigate("/creator/contacts/contacts")
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AddContacts