import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import { Button, Form, InputGroup } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import images from '../../../Utils/images';
import { useNavigate } from 'react-router-dom';
import { AiFillEyeInvisible, AiFillEye, AiOutlineInfoCircle } from "react-icons/ai";
import { eventsend } from '../../../Config/analyticsFunctions';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { trackEvent } from '../../../Services';


export default function NewAuthLogin({
    loginAction,
    googleloginaction,
    agentLogin,
    setAgentLogin,
    password,
    setPassword,
    newLoginAction,
    passwordAction,
    setIsOpen,
    facebookId,
    setFacebookId,
    invalidCredentials,
    // incorrectEmail
    // EETrial,
}) {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const toggle = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        eventsend('login_pageview_web');
    }, [])

    const token = localStorage.getItem('token');
    useEffect(() => {
        if (token) {
            navigate('/creator');
        }
    }, [token]);

    const onClickForgot = () => {
        //onClick={() => passwordAction(true) && loginAction(false) && setIsOpen(true)}
        passwordAction(true);
        setIsOpen(true);
    }



    const newLogin = () => {
        //  loginAction();
        eventsend("Login_Login_page_clicked");
        newLoginAction();
    };

    const handleTrackEvent = async (event) => {
        if (facebookId !== "priam@cheerio.in") {
            try {
                const data = {
                    email: "facebookId@cheerio.in",
                    event: event,
                    properties: {
                        userInput: facebookId,
                    }
                }
                const response = await trackEvent(data)
                if (response?.flag) {
                    localStorage.setItem('login_event', 'true');
                    console.log('event tracked successfully')
                }
                else {
                    console.log('error in track event')
                }
            } catch (error) {
                console.log('error in track event', error);
            }
        }
    }

    return (
        <div
            className="auth_authlogin"
        >
            {/* <form
                onSubmit={(e) => {
                    e.preventDefault();
                    newLogin();
                }}
            > */}
            <div className="auth_authlogin__body">
                <form
                    onSubmit={(e) => {
                        handleTrackEvent('login_button_clicked');
                        e.preventDefault();
                        eventsend('login_button_clicked_web');
                        newLogin();
                    }}
                >
                    <div className="row_1">
                        <h2>
                            {'Login to Cheerio'}
                        </h2>
                        {/* Partner Login from login page */}
                        {/* <div className='d-flex flex-row justify-content-end align-items-center' 
                            style={{cursor:'pointer'}}
                            onClick={()=>{
                                navigate('/partner')
                            }}
                        >
                            <p style={{marginInlineEnd:6}}>
                                {'Login to Partner'}
                            </p>
                            <MdOutlineOpenInNew size={20} color={colors.linkblue} />
                        </div> */}

                    </div>

                    <div
                        className=""
                        style={{
                            justifyContent: 'left', paddingInline: 10, fontSize: 14, display: 'flex', alignItems: 'center',
                            width: '100%',
                            flexDirection: 'row',
                            fontWeight: 400,
                            color: colors.black,
                            cursor: 'pointer',
                            margin: 0,
                            padding: 0, marginTop: "5%"
                        }}
                    >
                        <Form.Check // prettier-ignore
                            inline
                            type={'radio'}
                            id={`radio-Owner`}
                            label={`Admin`}
                            style={{ marginInlineEnd: 20, padding: 5, marginLeft: '6%' }}
                            checked={!agentLogin}
                            onChange={() => setAgentLogin(false)}
                        />
                        <Form.Check // prettier-ignore
                            inline
                            type={'radio'}
                            id={`radio-Agent`}
                            label={`Agent`}
                            style={{ marginInlineEnd: 20, padding: 5, marginLeft: '6%' }}
                            checked={agentLogin}
                            onChange={() => setAgentLogin(true)}
                        />
                    </div>

                    <div className="d-flex flex-column justify-content-between align-items-center w-100">
                        <div className="my-3 form-group" style={{ width: '100%' }}>
                            <h6
                                className="form-label"
                                style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                            >
                                {agentLogin && 'EMAIL ID / GMAIL ID'}
                                {!agentLogin && 'EMAIL ID / GMAIL ID / Phone No.'}
                            </h6>
                            <input
                                className="form-control"
                                placeholder="Enter here"
                                id="FacebookId"
                                value={facebookId}
                                onChange={(e) => setFacebookId(e.target.value)}
                            />
                        </div>
                        <div className="my-3 form-group" style={{ width: '100%' }}>
                            <h6
                                style={{ fontSize: 16, fontWeight: 600, color: colors.black }}
                                className="form-label"
                            >
                                Password
                            </h6>
                            <div className="" style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                // marginTop: "auto",
                            }}
                            >
                                <input
                                    type={
                                        showPassword ? "text" : "password"
                                    }
                                    style={{}}
                                    className="form-control"
                                    id="UserPassword"
                                    placeholder="Enter your password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    minLength={8}
                                />
                                <span className="icon" style={{ marginLeft: '-10%', marginTop: '1%' }} onClick={toggle}>
                                    {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
                                </span>
                            </div>
                            <div className="send_otp_container__sub">
                                <p className='incorrect_otp'>
                                    {invalidCredentials && 'Invalid credentials. Please try again.'}
                                </p>
                            </div>
                            <div className="" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                // marginTop: "auto",
                            }}
                            >
                                <div
                                    className=""
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'flex-end',
                                        marginBlockStart: 20,
                                        // alignSelf: "center",
                                        // marginTop: "1%",
                                        marginBottom: "10%",
                                        marginLeft: "60%"
                                    }}
                                >
                                    <p
                                        style={{
                                            margin: 0,
                                            paddingTop: '1.5%',
                                            fontSize: 12,
                                            fontWeight: 400,
                                            color: colors.black,
                                        }}
                                    >
                                        <span
                                            style={{ color: colors.linkblue, cursor: 'pointer' }}
                                            onClick={onClickForgot}
                                        >
                                            {'Forgot Password?'}
                                        </span>
                                    </p>
                                </div>

                                <div className=""
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginBlockStart: 10,
                                        justifyItems: 'center',
                                        // alignSelf: "center",
                                        // marginTop: "1%",
                                        // marginBottom: "1%",
                                        width: "100%",
                                        borderRadius: 12,
                                        backgroundColor: colors.grey,
                                    }}>
                                    <span className="icon" style={{ marginLeft: '5%', marginRight: '2%', marginBottom: '5%', scale: '1.5' }}>
                                        <AiOutlineInfoCircle />
                                    </span>
                                    <p style={{

                                        color: colors.black,
                                        fontSize: 12,
                                        marginTop: "1%",
                                        //    marginBottom: "5%",
                                        padding: 4,
                                        fontWeight: 400,
                                    }}>
                                        Users who do not have a password, please click forgot password to generate a password. Non Indian users only enter your registered email.
                                    </p>
                                </div>
                                <div className="body_form__button_container">
                                    <button
                                        style={{ width: '100%', borderWidth: 0, height: 40, marginTop: "5%", }}
                                        type="submit"
                                        className="btn btn-dark"
                                    >
                                        Login to Cheerio
                                    </button>
                                </div>
                            </div>
                            <div className="start_trial" style={{ marginTop: "2%" }}>
                                <p>
                                    {'New to Cheerio? '}
                                    <span onClick={() => loginAction()} style={{ color: colors.linkblue, cursor: 'pointer', }}>
                                        {'Start a trial'}
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
            {/* </form> */}
        </div>
    );
}

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 30,
        justifyContent: 'right',
        width: '100%',
        paddingRight: 20,
        marginTop: 10,
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // justifyItems: "center",
        justifyContent: 'space-evenly',
        width: '100%',
        // marginTop: 30,
        paddingLeft: '25%',
        paddingRight: '25%',
        height: '100%',
    },
    row: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // marginTop: "10%",
    },
    forgotPass: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        // marginTop: "10%",
    },
    buttonStyle: {
        height: 40,
        // borderWidth: 1,
        // borderStyle: "solid",
        // borderColor: colors.white,
        borderRadius: 8,
        backgroundColor: colors.primary03,
        // display: "inline-block",
        fontSize: 16,
        fontWeight: 600,
        color: colors.black04,
        borderWidth: 1,
        width: '100%',
        backgroundColor: colors.white,
        borderColor: colors.greys01,
        // marginTop: 20,
        // marginBlockStart:20,
    },
    haveAc: {
        margin: 0,
        fontSize: 16,
        color: colors.darkgrey,
        marginLeft: 'auto',
        alignSelf: 'flex-end',
    },
    loginViaEmail: {
        fontSize: 12,
        padding: 5,
        paddingRight: 12,
        paddingLeft: 12,
        borderRadius: 8,
        color: colors.linkblue,
        marginLeft: 'auto',
        borderWidth: 0,
        backgroundColor: colors.white,
    },
    freeTrial: {
        margin: 0,
        padding: 0,
        fontSize: 22,
        color: colors.black,
        fontWeight: 700,
    },
};
