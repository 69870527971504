import React, { useEffect, useRef, useState } from 'react'
import colors from '../../../Utils/colors';
import { TextField } from '@mui/material';
import ToggleButtons from './ToggleButton';
import images from '../../../Utils/images';
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'



const CFTitle = ({ titleProps, updateTitleProps}) => {

    const fonts = [
        'Segoe UI',
        'Arial',
        'Courier New',
        'Georgia',
        'Times New Roman',
        'Verdana',
        'Roboto',
        'Open Sans',
        'Lato',
    ];

    const fontWeight = [
        'Light',
        'Regular',
        'Bold'
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log("dsfsdfsdf ",name,value)
        updateTitleProps({ [name]: value });
    };

  return (
    <>
    <div className='d-flex flex-column justify-content-start align-items-center w-100' style={{
        backgroundColor:colors.white01,
        borderRadius:12,
        border:`1px solid ${colors.borderwhite}`,
        paddingInline:20,
        paddingBlock:16,
        marginBlock:8,
    }}>
        <div className='d-flex flex-row justify-content-between align-items-center w-100'>
            <h4 className='mb-2' style={{
                fontSize: 18,
                fontWeight:700,
            }}>
                {'Title'}
            </h4>
            <ToggleButtons 
                alignment={titleProps.alignment}
                handleChange={(value)=>{
                    const name = 'alignment'
                    console.log("dsfsdfsdf ",name,value)
                    updateTitleProps({ [name]: value });
                }}
            />
        </div>

        <div className='d-flex flex-column justify-content-stretch align-items-center w-100'>
            <TextField 
                name="title"
                placeholder='Enter form title here'
                value={titleProps.title}
                onChange={handleChange}
                type='text'
                size='small'
                style={{marginBlock:8,width:'100%'}}
                className='btncustom'
            />
        </div>
        
        <div className='d-flex flex-row justify-content-between align-items-cneter w-100 my-2'>
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font'}
                </p>
                <Select
                    name='font'
                    value={titleProps.font}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fonts.map((font) => (
                        <MenuItem key={font} value={font}>
                            {font}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            
            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Size (in px)'}
                </p>
                <TextField 
                    name="fontSize"
                    value={titleProps.fontSize}
                    onChange={handleChange}
                    type='number'
                    size='small'
                    style={{marginBlock:8,width:'100%'}}
                    className='btncustom'
                />
            </div>

            <div className='d-flex flex-column justify-content-stretch align-items-start px-2 py-2' style={{flex:1}}>
                <p style={{fontWeight:500}}>
                    {'Font Weight'}
                </p>
                <Select
                    name='fontWeigth'
                    value={titleProps.fontWeigth}
                    onChange={handleChange}
                    style={{marginBlock:8,width:'100%'}}
                    size='small'
                >
                    {fontWeight.map((fontweight) => (
                        <MenuItem key={fontweight} value={fontweight}>
                            {fontweight}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            

        </div>
    </div>
    </>
  )
}

export default CFTitle