import React, { useEffect, useState } from 'react'
import colors from '../../Utils/colors';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CheerioButton from '../../Components/CheerioButton';
import { getUserApi, partnerLogin } from '../../Services';
import { Route, Routes, useNavigate } from 'react-router-dom';
import ResellerDashboard from './Components/ResellerDashboard';
import ResellerForgotPassword from './Components/ResellerForgotPassword';
import { toast } from 'react-toastify';
import Helper from '../../Utils/Helper';

const ResellerLogin = () => {

  const [facebookId, setFacebookId] = useState('');
  const [password, setPassword] = useState('');
  const [partnerData, setPartnerData] = useState([]);
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);
  const [partnerCheerioData, setPartnerCheerioData] = useState([])

  const navigate = useNavigate();

  useEffect(() => {
    if (window.partnerCheerioToken) {
      getuserapicall();
    }
  }, [])

  const getuserapicall = () => {
    let token = window.partnerCheerioToken;
    getUserApi(token).then((res) => {
      console.log('get user api response ==> ', res);
      if (res?.flag) {
        setPartnerCheerioData(res?.data);
      } else {
        console.log("err ", res)
      }
    });
  };

  const partnerLoginAPICall = () => {
    let data = {
      facebookId: facebookId,
      password: password,
    }
    partnerLogin(data).then((res) => {
      if (res?.flag) {
        setPartnerData(res?.data)
        Helper.PartnerToken = res?.data?.token;
        navigate('/partner/dashboard')
      } else {
        toast.error('Incorrect login credentials entered', { position: 'top-right' })
      }
    })
  }


  return (
    <>
      <div className='reseller_container'>
        {isForgotModalOpen &&
          <ResellerForgotPassword
            setIsModalOpen={setIsForgotModalOpen}
          />
        }
        {location.pathname === '/partner' && (
          <>
            <div className='reseller_login_container'>
              <h3>
                {'Login as Cheerio Partner'}
              </h3>
              <p style={{ fontSize: 14, fontWeight: 400, color: colors.greys04, width: '100%' }}>
                {'Use your app.cheerio.in credentials to login'}
              </p>
              <div className='reseller_login_container__form_div'>
                <InputGroup className="my-3">
                  <Form.Label className='form_heading_style'>
                    {'EMAIL ID / Phone No.'}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter here"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={facebookId}
                    onChange={(e) => {
                      setFacebookId(e.target.value);
                    }}
                  />
                </InputGroup>
                <InputGroup className="my-3">
                  <Form.Label className='form_heading_style'>
                    {'Password'}
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    className="w-100 btncustom"
                    style={{ borderRadius: 8 }}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </InputGroup>
                <div className='forgot_password_div'>
                  <p className='password_text_style'
                    onClick={() => { setIsForgotModalOpen(true) }}
                  >
                    {'Forgot password?'}
                  </p>
                </div>
              </div>
              <CheerioButton
                borderStyle={{
                  borderColor: colors.black,
                  width: '100%',
                  paddingBlock: 10,
                }}
                textStyle={{ fontSize: 14, fontWeight: 600, color: colors.white01 }}
                btnText={'Login'}
                backColor={colors.black}
                disabled={(facebookId && password) ? false : true}
                onclick={() => {
                  partnerLoginAPICall();
                }}
              />
            </div>
          </>
        )}
        <Routes>
          <Route path='dashboard' element={<ResellerDashboard partnerData={partnerData} partnerCheerioData={partnerCheerioData} />} />
        </Routes>
      </div>

    </>
  )
}

export default ResellerLogin