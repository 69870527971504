import React, { useEffect, useState } from 'react';
import colors from '../../../Utils/colors';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { GetTemplatesV2API, getClientAttributes } from '../../../Services';
import { useSelector } from 'react-redux';
import AnnDetails from '../../AnnouncementsNew/Components/AnnDetails';
import AnnDetailsWF from './AnnDetailsWF';
import SpinnerLoader from '../../../Components/SpinnerLoader';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Tooltip } from '@mui/material';
import { Typeahead } from 'react-bootstrap-typeahead';

const SendWATemplateModal = ({
  data,
  setData,
  btnDisabled,
  setBtnDisabled,
  prevNode,
  keywordsAction = false,
  kaID,
  shopifyExist,
}) => {
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateData, setTemplateData] = useState([]);

  const [ItemV2, setItemV2] = useState([]);
  // const [mediaIDV2, setMediaIDV2] = useState('');
  const [headerTypeV2, setHeaderTypeV2] = useState('');
  const [headerFileNameV2, setHeaderFileNameV2] = useState('');
  // const [URLValueV2, setURLValueV2] = useState('');
  const [BodyParV2, setBodyParV2] = useState([]);
  // const [ImageV2, setImageV2] = useState('');
  // const [mpm, setMpm] = useState(false);
  const [s3Link, setS3Link] = useState('');

  const [actionList, setActionList] = useState({});
  const [headerInputText, setHeaderInputText] = useState('');
  // const [headerInputExists, setHeaderInputExists] = useState(false);

  // const [posURL, setPosURL] = useState(null);
  const [annv2CTA, setAnnv2CTA] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const [existingData, setExistingData] = useState();
  const [quickReplyBtn, setQuickReplyBtn] = useState([]);
  const [attributesList, setAttributesList] = useState(['name', 'email', 'phone']);
  const authtoken = useSelector((state) => state.main.auth_token);
  const [skipReply, setSkipReply] = useState(false);
  const [carouselCard, setCarouselCard] = useState([]);
  const [headerOption, setHeaderOption] = useState(data?.headerOption ?? 'uploadShopify');
  const [selectedOption, setSelectedOption] = useState(null);
  const [setOnFocus, setSetOnFocus] = useState(false);

  console.log('existingData', existingData);

  useEffect(() => {
    setBtnDisabled(true);
    setIsLoading(true);
    setExistingData(data);
    GetTemplatesV2APIcall();
    getUserAttributesAPICall();
    // if(!existingData?.templateID && keywordsAction){
    if (keywordsAction) {
      console.log('777777777 ', kaID);
      setSelectedTemplate(kaID);
    }
  }, []);

  useEffect(() => {
    if (templateData) {
      // console.log("useeffect called......",existingData)
      if (existingData?.templateID) {
        setSelectedOption(existingData?.templateName);
        setSelectedTemplate(existingData?.templateID);
      }
    }
  }, [templateData]);

  useEffect(() => {
    if (ItemV2) {
      // setTimeout(() => {
      //     loadExistingData();
      // }, 2000);
      // setTimeout(() => {
      loadExistingData();
      // }, 2000);
    }
  }, [ItemV2]);

  const loadExistingData = () => {
    if (existingData) {
      setHeaderTypeV2(existingData?.header?.type);
      setHeaderFileNameV2(existingData?.header?.fileName);
      setBodyParV2(existingData?.bodyParameters);
      setActionList(existingData?.mpm);
      setHeaderInputText(existingData?.header?.text);
      // console.log("culprit 1....")
      setAnnv2CTA(existingData?.cta);
      setS3Link(existingData?.header?.imgeURL);
      setSkipReply(existingData?.skip);
      setCarouselCard(existingData?.carouselCards);
    }
  };

  // useEffect(()=>{
  //     if(data){
  //         setSelectedTemplate(data?.templateID);
  //         setMediaIDV2(data?.header?.mediaId);
  //         setHeaderTypeV2(data?.header?.type);
  //         setHeaderFileNameV2(data?.header?.fileName);
  //         setURLValueV2(data?.cta?.value);
  //         setBodyParV2(data?.bodyParameters);
  //         setActionList(data?.mpm);
  //         setHeaderInputText(data?.header?.text)
  //         setPosURL(data?.cta?.index)
  //         setAnnv2CTA(data?.cta)
  //         setS3Link(data?.header?.imgeURL)
  //     }
  // },[])

  useEffect(() => {
    checkValidation();
  }, [data]);
  //console.log('dddd', prevNode);
  useEffect(() => {
    setData({
      ...data,
      templateName: ItemV2[0]?.name,
      templateID: selectedTemplate,
      language_code: ItemV2[0]?.language,

      cta: annv2CTA,
      header: {
        type: headerTypeV2,
        text: headerInputText,
        imgeURL: s3Link,
        fileName: headerFileNameV2 ? headerFileNameV2 : null,
      },
      bodyParameters: BodyParV2,
      quickReplyButtons: quickReplyBtn,
      mpm: actionList,
      skip: skipReply,
      carouselCards: carouselCard,
      headerOption: headerOption,
    });
    checkValidation();
  }, [
    selectedTemplate,
    headerTypeV2,
    headerFileNameV2,
    BodyParV2,
    actionList,
    headerInputText,
    ItemV2,
    annv2CTA,
    s3Link,
    quickReplyBtn,
    skipReply,
    carouselCard,
    headerOption,
  ]);

  const getUserAttributesAPICall = () => {
    let token = authtoken;
    getClientAttributes(token).then((res) => {
      if (res?.flag) {
        setAttributesList((prev) => {
          const arr = res?.data.map((item) => item.attribute);
          return [...prev, ...arr];
        });
        // console.log("user attributes success res ---> ",res);
      } else {
        // console.log("user attributes failed res ---> ",res);
      }
    });
  };

  const checkValidation = () => {
    let ctaValid = annv2CTA?.filter((item, index) => {
      return item.value === '';
    });
    let bodyParValid = BodyParV2?.filter((item, index) => {
      return item === '';
    });
    let headerValid =
      (headerTypeV2 && s3Link && headerFileNameV2) ||
      (headerTypeV2 === 'text' && headerInputText) ||
      (headerTypeV2 === 'text' && !headerInputText) ||
      data?.headerOption === 'fetchShopify' || data?.headerOption === 'fetchWoo' ||
      headerTypeV2 === 'NONE';
    let mpmValid =
      actionList?.index &&
      actionList?.thumbnail_product_retailer_id &&
      actionList?.sections?.length !== 0 &&
      actionList?.sections?.filter((item, index) => {
        return !item?.title || item?.product_items?.length === 0;
      }).length !== 0;
    let carouselValid =
      carouselCard?.filter((item, index) => {
        return (
          item?.header?.link === '' ||
          !item?.bodyParameters ||
          item?.bodyParameters?.includes('', 0) ||
          item?.cta?.some((ctaItem) => ctaItem?.value === '')
        );
      })?.length === 0;
    // console.log("================= ", data);
    // console.log("================= ",data?.templateName);
    // console.log("================= ",data?.templateID);
    // console.log("================= ",data?.language_code);
    // console.log("================= ",((data?.cta && ctaValid?.length === 0) || !data?.cta));
    // console.log("================= ",(data?.cta && ctaValid?.length === 0));
    // console.log("================= ",(data?.header && headerValid));
    // console.log("================= ",(data?.header && headerValid));
    // console.log("================= ",((data?.bodyParameters && bodyParValid?.length === 0) || !data?.bodyParameters ));
    // console.log("================= ",(data?.bodyParameters && bodyParValid?.length === 0));
    // console.log("================= ",((data?.mpm && mpmValid) || data?.mpm?.index === null || !data?.mpm));
    // console.log("================= ", ((data?.carouselCards && data?.carouselCards?.length === 0) || !data?.carouselCards));
    if (
      data?.templateName &&
      data?.templateID &&
      data?.language_code &&
      ((data?.cta && ctaValid?.length === 0) || !data?.cta) &&
      data?.header &&
      headerValid &&
      ((data?.bodyParameters && bodyParValid?.length === 0) || !data?.bodyParameters) &&
      ((data?.mpm && mpmValid) || data?.mpm?.index === null || !data?.mpm) &&
      ((data?.carouselCards && carouselValid) || !data?.carouselCards)
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  useEffect(() => {
    if (selectedTemplate && templateData) {
      // console.log("set item useeffect called.....")
      setIsLoading(true);
      setItemV2(templateData.filter((e) => e.id === selectedTemplate));
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
  }, [selectedTemplate, templateData]);

  const GetTemplatesV2APIcall = () => {
    let token = authtoken;
    let data = {
      limit: 500,
    };
    GetTemplatesV2API(data, token).then((res) => {
      if (res) {
        console.log('TemplatesV2API response: ' + res);
        setTemplateData(res?.data);
        const filteredTemplates = res?.data.filter((item, index) => {
          return item?.status === 'APPROVED';
        });
        // setSelectedTemplate(filteredTemplates[0]?.id);
      } else {
        console.log('errror: ' + res);
      }
    });
  };

  useEffect(() => {
    if (templateData) {
      setIsLoading(false);
    }
  }, [templateData]);

  useEffect(() => {
    if (!selectedOption) {
      setSetOnFocus(false);
    }
  }, [selectedOption]);

  return (
    <>
      <div className="w-100 h-100 flex-column justify-content-start align-items-start">
        <InputGroup className="mb-3" style={{ width: '100%', marginBlock: 20 }}>
          <div style={{ height: setOnFocus ? 200 : '', width: '100%' }}>
            <Form.Label style={{ fontSize: 14, fontWeight: 600, color: colors.black }}>
              {'Select Template'}
            </Form.Label>
            <Typeahead
              id="labelNameTypeahead"
              labelKey="name"
              options={
                templateData
                  ? templateData.filter((item, index) => {
                    return item?.status === 'APPROVED';
                  })
                  : []
              }
              placeholder="Choose Template"
              className="w-100 btncustom dropdown"
              style={{
                borderRadius: 8,
                fontSize: 16,
                fontWeight: 400,
                color: '#6c757d',
                width: '100%',
              }}
              selected={selectedOption ? [selectedOption] : []}
              onChange={(selected) => {
                if (selected?.length > 0) {
                  setSelectedOption(selected[0]);
                  setSelectedTemplate(selected[0]?.id);
                  setSetOnFocus(false);
                } else {
                  setSelectedOption(null);
                  setSetOnFocus(false);
                }
              }}
              onFocus={() => setSetOnFocus(true)}
              onBlur={() => setSetOnFocus(false)}
            />
          </div>
          {shopifyExist && (
            <div style={{ color: 'gray', padding: 5 }}>
              {
                'NOTE: A link button might be mandatory for your templates to work. (Mandatory for messages such as abandon cart, order fulfill or any other template that expects an URL)'
              }
            </div>
          )}
        </InputGroup>

        {isLoading ? (
          <>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 my-3">
              <SpinnerLoader />
            </div>
          </>
        ) : (
          <>
            {ItemV2[0] && (
              <AnnDetailsWF
                item={ItemV2[0]}
                setHeaderType={setHeaderTypeV2}
                headerType={headerTypeV2}
                headerFileName={headerFileNameV2}
                setHeaderFileName={setHeaderFileNameV2}
                bodyPar={BodyParV2}
                setBodyPar={setBodyParV2}
                actionList={actionList}
                setActionList={setActionList}
                headerInputText={headerInputText}
                setHeaderInputText={setHeaderInputText}
                setAnnv2CTA={setAnnv2CTA}
                s3Link={s3Link}
                setS3Link={setS3Link}
                existingData={existingData}
                quickReplyBtn={quickReplyBtn}
                setQuickReplyBtn={setQuickReplyBtn}
                inputFieldDropDown={shopifyExist}
                attributesList={attributesList}
                workFlow={true}
                setCarouselCardAPI={setCarouselCard}
                isShopifyHeader={prevNode && (prevNode?.type === 'shopify' || prevNode?.type === 'wooWFNode') ? true : false}
                setHeaderOption={setHeaderOption}
                nodeType={prevNode?.type}
                headerOption={headerOption}
              />
            )}
          </>
        )}
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center w-100 mb-4 mt-2">
        <Form.Check
          // disabled={disabled}
          type="checkbox"
          className="btncustom"
          // value={skipReply ? true : false}
          checked={skipReply}
          label="Skip user reply"
          onChange={() => {
            setSkipReply(!skipReply);
          }}
        />
        <Tooltip
          title={'Next node will trigger itself even if recipient does not reply'}
          placement="bottom-end"
        >
          <div>
            <AiOutlineInfoCircle size={20} style={{ marginInlineEnd: 16 }} />
          </div>
        </Tooltip>
      </div>
    </>
  );
};

export default SendWATemplateModal;
