import { useEffect, useState } from 'react';
import '../styles.css';
import images from '../../../Utils/images';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CheerioButton from '../../../Components/CheerioButton';
import { useSelector } from 'react-redux';
import colors from '../../../Utils/colors';
import { ClickAwayListener } from '@mui/material';
import { eventsend } from '../../../Config/analyticsFunctions';

const DateSelector = ({ filter, setFilters }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: '10px', marginTop: '10px' }}
      >
        <div style={{ width: '50%' }}>
          <DatePicker
            slotProps={{ textField: { size: 'small', placeholder: 'Start' } }}
            sx={{
              '& .MuiInputBase-root': {
                height: '25px',
              },
            }}
            disabled={filter.day.selectedNumberOfDate !== 'Select'}
            value={filter.day.startDate}
            onChange={(newValue) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  day: {
                    ...prev.day,
                    startDate: newValue,
                  },
                };
              });
            }}
          />
        </div>
        <div style={{ marginLeft: '10px', marginRight: '10px' }}>-</div>
        <div style={{ width: '50%' }}>
          <DatePicker
            slotProps={{ textField: { size: 'small', placeholder: 'End' } }}
            sx={{
              '& .MuiInputBase-root': {
                height: '25px',
              },
            }}
            disabled={filter.day.selectedNumberOfDate !== 'Select'}
            value={filter.day.endDate}
            onChange={(newValue) => {
              setFilters((prev) => {
                return {
                  ...prev,
                  day: {
                    ...prev.day,
                    endDate: newValue,
                  },
                };
              });
            }}
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export const Filters = ({
  setShowFilters,
  filters,
  setFilters,
  agents,
  setInboxList,
  // getInboxListing,
  setPage,
  setChatData,
  setTopFilter,
  setAppliedFilter,
  removeFilterAction,
  sortOrder,
  setSortOrder,
}) => {
  const [toggleFilter, setToggleFilter] = useState({
    general: false,
    status: false,
    day: false,
    agent: false,
    ctwa: false,
  });
  const filterOptions = {
    general: [
      { option: 'Unexpired Chats', value: 'true' },
      { option: 'Expired Chats', value: 'false' },
      { option: 'Unread messages', value: 'Unread' },
      { option: 'Blocked Chats', value: 'Blocked' },

      // 'Catalogue sent cart',
      // 'Flows from response',
      // 'Button replies',
      // 'Typed replies',
    ],
    status: [
      { option: 'Resolved queries', value: 'Resolved' },
      { option: 'Open queries', value: 'Unresolved' },
      { option: 'Pending queries', value: 'Pending' },
    ],
    day: ['1', '2', '3', '7', '30', 'Select'],
    agent: {
      0: {
        _id: 'Unassigned',
        name: 'Unassigned',
      },
    },
    ctwa: [{ option: 'Show Ctwa', value: false }],
  };
  Object.values(agents).forEach((v, i) => {
    filterOptions.agent[i + 1] = v;
  });

  const agentUserData = useSelector((state) => state.main.agentdata);
  const MobileWidth = window.innerWidth < 900 ? true : false;

  // console.log('FL', filters);
  // console.log('otions', filterOptions);

  function allValuesFalseOrNullEmpty(obj) {
    return Object.values(obj).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (value && typeof value === 'object') {
        return allValuesFalseOrNullEmpty(value);
      } else {
        return value === false || value === null;
      }
    });
  }

  const showRemoveFilters = allValuesFalseOrNullEmpty(filters);
  const handleChange = (e, option) => {
    if (e.target.checked) {
      if (option === 'general') {
        setFilters((prev) => {
          return { ...prev, general: [...prev.general, e.target.value] };
        });
      }
      if (option === 'status') {
        setFilters((prev) => {
          return { ...prev, status: [...prev.status, e.target.value] };
        });
      }
      if (option === 'agent') {
        setFilters((prev) => {
          return { ...prev, agents: [...prev.agents, e.target.value] };
        });
      }
    } else {
      if (option === 'general') {
        setFilters((prev) => {
          const newArr = filters.general.filter((item) => item !== e.target.value);
          return { ...prev, general: newArr };
        });
      }
      if (option === 'status') {
        setFilters((prev) => {
          const newArr = filters.status.filter((item) => item !== e.target.value);
          return { ...prev, status: newArr };
        });
      }
      if (option === 'agent') {
        setFilters((prev) => {
          const newArr = filters.agents.filter((item) => item !== e.target.value);
          return { ...prev, agents: newArr };
        });
      }
    }
  };

  useEffect(() => {
    eventsend('Filters_Inbox_page_clicked');
  }, []);

  return (
    <ClickAwayListener onClickAway={() => setShowFilters(false)}>
      <div
        class="filterDropdown"
        style={{
          padding: '10px',
          width: MobileWidth ? '100%' : window.innerWidth * 0.2,
          marginInlineStart: MobileWidth ? 0 : 85,
          top: MobileWidth ? '80px' : '70px',
          borderRadius: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '10px',
            justifyContent: 'space-between',
          }}
        >
          <p style={{ fontWeight: '700' }}>Filters</p>
          <img
            src={images.CMClose}
            onClick={() => setShowFilters(false)}
            style={{
              cursor: 'pointer',
              height: 20,
              width: 20,
            }}
          ></img>
        </div>
        <hr style={{ width: '100%', marginTop: '-2px', marginBottom: '-2px' }}></hr>
        <div
          style={{
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            height: '60vh',
            padding: '15px',
            marginBottom: '20px',
          }}
        >
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setToggleFilter((prev) => {
                  const obj = { ...prev, general: !prev.general };
                  return obj;
                })
              }
            >
              <p style={{ fontWeight: '700', fontSize: '14px' }}>General</p>
              <img
                id="GeneralToggle"
                height={20}
                width={20}
                style={{ marginTop: '2px' }}
                src={toggleFilter.general ? images.Up : images.Down}
              ></img>
            </div>
            {toggleFilter.general &&
              filterOptions.general.map((option) => (
                <div className="d-flex" style={{ marginTop: '3%' }}>
                  <input
                    id={option.option}
                    type="checkbox"
                    value={option.value}
                    checked={
                      filters.general.length > 0 && filters.general.includes(option.value)
                        ? true
                        : false
                    }
                    style={{ height: '20px', width: '18px', marginRight: '10px ' }}
                    onChange={(e) => {
                      console.log('e', e.target.value);
                      handleChange(e, 'general');
                    }}
                  />
                  <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option.option}</p>
                </div>
              ))}
            <hr style={{ marginTop: '10px' }}></hr>
          </div>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setToggleFilter((prev) => {
                  const obj = { ...prev, status: !prev.status };
                  return obj;
                })
              }
            >
              <p style={{ fontWeight: '700', fontSize: '14px' }}>Filter by status</p>
              <img
                height={20}
                width={20}
                style={{ marginTop: '2px' }}
                onclick={() =>
                  setToggleFilter((prev) => {
                    const obj = { ...prev, status: !prev.status };
                    return obj;
                  })
                }
                src={toggleFilter.status ? images.Up : images.Down}
              ></img>
            </div>
            {toggleFilter.status &&
              filterOptions.status.map((option) => (
                <div className="d-flex" style={{ marginTop: '3%' }}>
                  <input
                    type="checkbox"
                    checked={
                      filters.status.length > 0 && filters.status.includes(option.value)
                        ? true
                        : false
                    }
                    style={{ height: '20px', width: '18px', marginRight: '10px ' }}
                    value={option.value}
                    onChange={(e) => {
                      handleChange(e, 'status');
                    }}
                  />
                  <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option.option}</p>
                </div>
              ))}
            <hr style={{ marginTop: '10px' }}></hr>
          </div>
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ marginTop: '2px', cursor: 'pointer' }}
              onClick={() =>
                setToggleFilter((prev) => {
                  const obj = { ...prev, day: !prev.day };
                  return obj;
                })
              }
            >
              <p style={{ fontWeight: '700', fontSize: '14px' }}>Filter by day</p>
              <img
                height={20}
                width={20}
                style={{ marginTop: '2px' }}
                src={toggleFilter.day ? images.Up : images.Down}
              ></img>
            </div>
            {/* {toggleFilter.day &&
            filterOptions.day.map((option) => (
              <>
                <div className="d-flex">
                  <input
                    type="checkbox"
                    style={{ height: '20px', width: '18px', marginRight: '10px ' }}
                    onChange={() => {}}
                  />
                  <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option}</p>
                </div>
              </>
            ))} */}
            <form
              onChange={(e) => {
                setFilters((prev) => {
                  return {
                    ...prev,
                    day: {
                      ...prev.day,
                      selectedNumberOfDate: e.target.value,
                    },
                  };
                });
              }}
            >
              {toggleFilter.day &&
                filterOptions.day.map((option) => (
                  <>
                    <div className="d-flex" style={{ marginTop: '3%' }}>
                      <input
                        type="radio"
                        style={{ width: '16px', height: '16px', marginRight: '6px' }}
                        id={option}
                        checked={option === filters.day.selectedNumberOfDate ? true : false}
                        name={'group'}
                        value={option}
                      />
                      <label for={option} style={{ marginTop: '-4px', fontSize: '16px' }}>
                        {option == 1 ? 'Today' : option == 2 ? 'Yesterday' : option + ' days'}
                      </label>
                      {/* <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option}</p> */}
                    </div>
                    {option === 'Select' && (
                      <DateSelector filter={filters} setFilters={setFilters}></DateSelector>
                    )}
                  </>
                ))}
            </form>
            <hr style={{ marginTop: '10px' }}></hr>
          </div>
          {(agentUserData?.permission?.allChats || agentUserData?.role !== 'agent') && (
            <div>
              <div
                className="d-flex justify-content-between"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  setToggleFilter((prev) => {
                    const obj = { ...prev, agent: !prev.agent };
                    return obj;
                  })
                }
              >
                <p style={{ fontWeight: '700', fontSize: '14px' }}>Filter by Agent</p>
                <img
                  height={20}
                  width={20}
                  style={{ marginTop: '2px' }}
                  src={toggleFilter.agent ? images.Up : images.Down}
                ></img>
              </div>
              {toggleFilter.agent &&
                Object.values(filterOptions.agent).map((option) => (
                  <>
                    <div className="d-flex" style={{ marginTop: '3%' }}>
                      <input
                        type="checkbox"
                        style={{ height: '20px', width: '18px', marginRight: '10px ' }}
                        checked={
                          filters.agents.length > 0 && filters.agents.includes(option?._id)
                            ? true
                            : false
                        }
                        value={option?._id}
                        onChange={(e) => {
                          handleChange(e, 'agent');
                        }}
                      />
                      <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option?.name}</p>
                    </div>
                    {/* {option === 'Manual' && <DateSelector></DateSelector>} */}
                  </>
                ))}
              <hr style={{ marginTop: '10px' }}></hr>
            </div>
          )}
          <div>
            <div
              className="d-flex justify-content-between"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                setToggleFilter((prev) => {
                  const obj = { ...prev, ctwa: !prev.ctwa };
                  return obj;
                })
              }
            >
              <p style={{ fontWeight: '700', fontSize: '14px' }}>Filter by CTWA</p>
              <img
                height={20}
                width={20}
                style={{ marginTop: '2px' }}
                src={toggleFilter.ctwa ? images.Up : images.Down}
              ></img>
            </div>
            {toggleFilter.ctwa &&
              filterOptions.ctwa.map((option) => (
                <div className="d-flex" style={{ marginTop: '3%' }}>
                  <input
                    type="checkbox"
                    value={option.value}
                    checked={filters.showCtwa ? true : false}
                    style={{ height: '20px', width: '18px', marginRight: '10px ' }}
                    onChange={(e) => {
                      eventsend('Filters_clicked?');
                      console.log('e value', e.target.checked);
                      if (e.target.checked) {
                        setFilters((prev) => {
                          return { ...prev, showCtwa: true };
                        });
                      } else {
                        setFilters((prev) => {
                          return { ...prev, showCtwa: false };
                        });
                      }
                    }}
                  />
                  <p style={{ marginTop: '-2px', fontSize: '16px' }}>{option.option}</p>
                </div>
              ))}
            <hr style={{ marginTop: '10px' }}></hr>
          </div>
        </div>
        <div className="d-flex" style={{ marginBottom: '10px', alignItems: 'center' }}>
          <input
            type="checkbox"
            style={{ height: '16px', width: '16px', marginRight: '10px ' }}
            checked={sortOrder === 1 ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                setSortOrder(1);
              } else {
                setSortOrder(-1);
              }
            }}
          />
          <p style={{ fontSize: '14px' }}>{'Show old chats first'}</p>
        </div>
        {!showRemoveFilters && (
          <CheerioButton
            id="RemoveFilterButton"
            btnText={'Remove filters'}
            textStyle={{ color: colors.primary03, fontSize: '12px', fontWeight: '600' }}
            // backColor={'#9357FF'}
            backColor={colors.white01}
            //icon={images.fillFilter}
            borderStyle={{
              width: '100%',
              height: '10%',
              marginBlockEnd: 8,
              borderColor: colors.primary03,
              borderRadius: '8px',
            }}
            onclick={() => {
              eventsend('Remove_filters_Inbox_page_clicked');
              // getInboxListing();
              removeFilterAction();
              setTopFilter(false);
              setAppliedFilter('All');
              setPage(1);
              // setInboxList([]);
              // getInboxListing();
              // setShowFilters(false);
              // setChatData(null);
            }}
          />
        )}
        <CheerioButton
          id="FilterButton"
          btnText={'Apply filters'}
          textStyle={{ color: 'white', fontSize: '12px', fontWeight: '600' }}
          backColor={'#9357FF'}
          //icon={images.fillFilter}
          borderStyle={{
            width: '100%',
            height: '10%',
            border: 'none',
            borderRadius: '8px',
            marginBlockStart: 8,
          }}
          onclick={() => {
            eventsend('Apply_filters_Inbox_page_clicked');
            //getInboxListing();
            setPage(1);
            setInboxList([]);
            // getInboxListing(1);
            setTopFilter(true);
            setShowFilters(false);
            setChatData(null);
            if (showRemoveFilters) {
              setTopFilter(false);
            }
          }}
        />
      </div>
    </ClickAwayListener>
  );
};
