import React, { useEffect, useRef, useState } from 'react';
import CheerioButton from '../../Components/CheerioButton';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { V2EmailBuilderList } from '../../Services';
import colors from '../../Utils/colors';
import images from '../../Utils/images';
import Setup from '../ShopifyStore/Components/Setup';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import WooCommerce from '../WooCommerce';
import ShopifyStore from '../ShopifyStore';
import Kylas from './KylasIntegration';
import OpenAi from './OpenAiIntegration';
import { trackPages } from '../../Utils/brevo';
import NoIntegrations from './NoIntegrations';
import { getUserApi } from '../../Services';
import { eventsend } from '../../Config/analyticsFunctions';

const ManageIntegrations = () => {
  const localdata = useSelector((state) => state.main.localdata);
  const authtoken = useSelector((state) => state.main.auth_token);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const [selected, setSelected] = useState({
  //   name: 'WooCommerce',
  //   url: '',
  //   logo: images.woocommerceLogo,
  // });
  const specialID =
    localdata?._id === '636be67d42c9a5cb33f60d98' ||
    localdata?._id === '660e878b6d6015c4e49b0434' ||
    localdata?._id === '6489675b4f598e055ed74e34' ||
    localdata?._id === '65f7d39aecb11f98652fffcd' ||
    localdata?._id === '65f7d42aecb11f98652fffd2';
  const options = [
    {
      name: 'Shopify',
      url: 'shopify',
      logo: images.ShopifyLogo,
    },
    {
      name: 'WooCommerce',
      url: 'woocommerce',
      logo: images.woocommerceLogo,
    },
    {
      name: 'Kylas',
      url: 'kylas',
      logo: images.KylasLogo,
    },
    {
      name: 'Chat GPT',
      url: 'chatGpt',
      logo: images.OpenAiLogo,
    },
  ];
  const location = useLocation();
  const urlParams = location.pathname.split('/');
  const filteredOptions = options.filter((option) => localdata.integratedApp.includes(option.name));
  const [selected, setSelected] = useState(filteredOptions[0]);
  // useEffect(() => {
  //   if (urlParams.includes('shopify')) {
  //     setSelected(options[0]);
  //   }
  // }, [urlParams]);

  const getuserapicall = () => {
    let token = authtoken;
    getUserApi(token).then((res) => {
      // console.log('get user api response ==> ', res);
      if (res?.flag) {
        dispatch({ type: 'addLocaldata', payload: res?.data });
      } else {
        navigate('/');
        toast.error(res?.message);
      }
    });
  };

  const handleSelect = (option) => {
    setSelected(option);
    eventsend("Switch_platform_Manage_integration_page_clicked");
    navigate(`/creator/manageIntegrations/${option.url}`);
  };

  useEffect(() => {
    getuserapicall();
    trackPages('integrations_manage_integrations_page', localdata);
    if (urlParams.includes('shopify')) {
      setSelected(options[0]);
    }
    if (localdata?.integratedApp.length > 0 && selected?.url) {
      navigate(`/creator/manageIntegrations/${selected?.url}`);
    } else {
      navigate(`/creator/manageIntegrations`);
    }
  }, []);

  return (
    <div style={{ width: '100%', height: '100%' }}>
      {localdata?.integratedApp?.length > 0 && (
        <div
          className="d-flex justify-content-between"
          style={{
            borderBottom: '1px solid #CDCDCD',
            padding: '1%',
            width: '100%',
            backgroundColor: 'white',
            alignItems: 'center',
          }}
        >
          <p style={{ fontWeight: '700', fontSize: '20px' }}>Manage Integrations</p>
          <Dropdown
            style={{
              marginInlineStart: 6,
              borderRadius: '8px',
              minWidth: '15%',
            }}
          >
            <Dropdown.Toggle
              style={{
                backgroundColor: '#FAF9F6',
                borderRadius: '8px',
                color: 'black',
                border: 'none',
                padding: '10px 16px 10px 16px',
                fontWeight: '600',
                fontSize: '16px',
                width: '100%',
                display: 'flex', // Enable flexbox layout
                justifyContent: 'space-between', // Spread items evenly
                alignItems: 'center', // Align items vertically at the center
              }}
              variant="success"
              id="dropdown-basic"
            >
              <div>
                <img
                  style={{ width: '24px', height: '24px', marginRight: '10px' }}
                  src={selected.logo}
                ></img>
                {selected.name}{' '}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filteredOptions?.map((option, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() => {
                    if (option.url === 'chatGpt' && specialID) {
                      handleSelect(option);
                    } else if (option.url !== 'chatGpt') {
                      handleSelect(option);
                    }
                  }}
                >
                  {option.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      <Routes>
        <Route path="/" element={<NoIntegrations />}></Route>
        <Route path="shopify/*" element={<ShopifyStore></ShopifyStore>}></Route>
        <Route path="kylas" element={<Kylas></Kylas>}></Route>
        <Route path="woocommerce" element={<WooCommerce />}></Route>
        {specialID && <Route path="chatGpt" element={<OpenAi></OpenAi>}></Route>}
      </Routes>
    </div>
  );
};

export default ManageIntegrations;
