import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import colors from '../../Utils/colors';
import WAInbox from './components/WAInbox';
import WAUserProfile from './components/WAUserProfile';
import WAChatView from './components/WAChatView';
import InputGroup from 'react-bootstrap/InputGroup';
import { IoIosSearch } from 'react-icons/io';
import WAPreSetupModal from '../CreatorNew/Components/WAPreSetupModal';
import EmbeddedModal from '../CreatorNew/Components/EmbeddedModal';
import Form from 'react-bootstrap/Form';
import { io } from 'socket.io-client';
import {
  EmbeddedCheckWAStatus,
  V2AgentGet,
  getConversationByIDAPI,
  getInboxListingAPI,
} from '../../Services';
import { eventsend } from '../../Config/analyticsFunctions';
import { SOCKET_URL } from '../../Config/axiosConfig';
import SpinnerLoader from '../../Components/SpinnerLoader';
import { PremiumOptionModal } from '../../Components/Modal/PremiumOptionModal';
import { trackPages } from '../../Utils/brevo';
import images from '../../Utils/images';
import CheerioButton from '../../Components/CheerioButton';
import PreReqWASetup from '../CreatorNew/Components/PreReqWASetup';
import { getWorkFlowStatus } from '../../Services';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
//let chatTemp = {};
//let tempList = [];
// let selectedIndexTemp = 0;
const socket = io(SOCKET_URL, {
  maxHttpBufferSize: 1e7,
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
});

const WhatsAppReplies = ({ excepted }) => {
  console.log('sock', socket.id);
  const localdata = useSelector((state) => state.main.localdata);
  const navigate = useNavigate();
  const [inboxList, setInboxList] = useState([]);
  const [chatData, setChatData] = useState(null);
  //const [isChatLoad, setIsChatLoad] = useState(false);
  const [connection, setConnection] = useState(false);
  const firstRender = React.useRef(false);
  const [loading, setLoading] = useState(false);
  // const [selectedIndex, setSelectedIndex] = useState(-1);
  const [page, setPage] = useState(1);
  const [messagePage, setMessagePage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [searchInbox, setSearchInbox] = useState('');
  const [next, setNext] = useState(true);
  const [updateMsg, setUpdateMsg] = useState();
  const [showScreen, setShowScreen] = useState('inbox');
  const agentUserData = useSelector((state) => state.main.agentdata);
  const [is24, setIs24] = useState(); // Don't know what this is but removing it will break the code
  const [isWAPresetupModalOpen, setIsWAPresetupModalOpen] = useState(false);
  const [EmbeddedOpen, setEmbeddedOpen] = useState(false);
  const [WAStatus, setWAStatus] = useState(false);
  const [isEmbeddedModalOpen, setIsEmbeddedModalOpen] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState("All");
  const [filteredCount, setFilteredCount] = useState(0);
  const [sortOrder, setSortOrder] = useState(-1);

  //const showCTWA = useSelector((state) => state.main.showCTWA);
  const isChatLoad = localdata?.whatsapp_config == 'Active' ? true : false;
  const isPremiumUser = localdata?.premium;
  const [filters, setFilters] = useState({
    general: [],
    status: [],
    day: {
      selectedNumberOfDate: null,
      startDate: null,
      endDate: null,
    },
    agents:
      agentUserData?.role === 'agent'
        ? agentUserData?.permission?.allChats
          ? []
          : [agentUserData?._id]
        : [],
    showCtwa: false,
  });
  const [agentData, setAgentData] = useState();
  const authtoken = useSelector((state) => state.main.auth_token);
  const [totalChats, setTotalChats] = useState(0);
  const [loadingChatScreen, setLoadingChatScreen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [more, setMore] = useState(true);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [loadingInbox, setLoadingInbox] = useState(false);
  const [updateConversations, setUpdateConversations] = useState(false);
  const [activeWorkflow, setActiveWorkflow] = useState(false);
  const [resumeWorkflow, setResumeWorkflow] = useState(false);
  const [PRWAModalOpen, setPRWAModalOpen] = useState(false);
  const [isFTUser, setIsFTUser] = useState(false);
  const filterRef = useRef(filters);
  const searchRef = useRef(searchInbox);
  const [paneWidth, setPaneWidth] = useState(window.innerWidth * 0.3);
  const [isResizing, setIsResizing] = useState(false);
  const [typingUser, setTypingUser] = useState('');
  const [chatCount, setChatCount] = useState({
    All: -1,
    Unread: -1,
    Unresolved: -1,
    Pending: -1,
    Resolved: -1,
    ChatOpen: -1,
    ChatClosed: -1,
    OpenandPending:-1,
    ExpiredandPending:-1,
  });

  const handleMouseDown = (e) => {
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = e.clientX - 80;
      if (newWidth > 270 && newWidth < 800) {
        setPaneWidth(newWidth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  function updateQueryParam(param, value) {
    let url = new URL(window.location.href);
    url.searchParams.set(param, value);
    window.history.pushState({}, '', url);
  }

  function getQueryParam(param) {
    let url = new URL(window.location.href);
    return url.searchParams.get(param);
  }

  function removeQueryParam(param) {
    let url = new URL(window.location.href);
    url.searchParams.delete(param);
    window.history.replaceState({}, '', url);
  }

  useEffect(() => {
    let param_id = getQueryParam('id');
    if (inboxList.length > 0 && !chatData) {
      if (param_id && inboxList.some((item) => item?._id === param_id)) {
        let data = inboxList.filter((item) => item?._id === param_id);
        setChatData(data[0]);
        const conversationId = data[0]?._id;
        const name = agentUserData?.name ?? localdata?.name; 
        if (conversationId) {
          socket.emit('joinConversation', {conversationId, name});
        }
        setTypingUser(() => {
          if (data[0]?.typingName && data[0]?.typingName != '') {
            return `${data[0]?.typingName} is typing...`;
          } else {
            return '';
          }
        });
        setInboxList((prev) => {
          const newArr = [...prev];
          const index = newArr.findIndex((item) => item._id === data._id);
          if (index > -1) {
            newArr[index] = { ...data, unread_count: 0 };
          }
          return newArr;
        });
      } else {
        let data = inboxList[0];
        updateQueryParam('id', data?._id);
        setChatData(data);
        const conversationId = data?._id;
        const name = agentUserData?.name ?? localdata?.name; 
        if (conversationId) {
          socket.emit('joinConversation', {conversationId, name});
        }
        setTypingUser(() => {
          if (data?.typingName && data?.typingName != '') {
            return `${data?.typingName} is typing...`;
          } else {
            return '';
          }
        });
        setInboxList((prev) => {
          const newArr = [...prev];
          const index = newArr.findIndex((item) => item._id === data._id);
          if (index > -1) {
            newArr[index] = { ...data, unread_count: 0 };
          }
          return newArr;
        });
      }
    }
  }, [inboxList]);

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    filterRef.current = filters;
  }, [filters]);

  useEffect(() => {
    searchRef.current = searchInbox;
    // console.log('Search', searchInbox);
  }, [searchInbox]);

  const getRemainingDays = (e) => {
    const d = new Date(e);
    const date = new Date();
    let dd = d.toDateString();
    let cd = date.toDateString();
    let diff = Math.floor((Date.parse(dd) - Date.parse(cd)) / 86400000);
    return diff;
  };

  const remainingDays = getRemainingDays(localdata?.free_trail?.end_date);
  useEffect(() => {
    if (remainingDays > 0) {
      setIsFTUser(true);
    }
  }, [localdata]);
  //chat interface
  // {
  //   status: String,
  //   agent: {
  //     id: { type: mongoose.Schema.Types.ObjectId, ref: "SubUsers" },
  //     name: { type: String, ref: "SubUsers" },
  //   },
  //   merchant_details: {
  //     sender_id: String,
  //     phone: String,
  //   },
  //   user_details: {
  //     name: String,
  //     phone: String,
  //   },
  //   user_reply: Boolean,
  //   text: String,
  //   image: String,
  //   unread_count: Number,
  //   merchant_reply: Boolean,
  //   time: Date,
  //   user_profile: String,
  //   balance: Number,
  //   stop: Boolean,
  //   waReferral: {
  //     source_url: String,
  //     source_id: String,
  //     source_type: String,
  //     body: String,
  //     headline: String,
  //     thumbnail_url: String,
  //     image_url: String,
  //     ctwa_clid: String,
  //     media_type: String,
  //   },
  // }

  // const socket = SocketConnect.newSocketConnect();

  const MobileWidth = window.innerWidth < 900 ? true : false;

  const socketId = socket?.id;

  function afterConnectionSetup(newMessage) {
    // if (socket.connected && !connection) {
    setConnection(true);
    setUpdateConversations(true);
    console.log('triggere');
    //   // setInboxList([]);
    // }
    setLoadingChatScreen(false);
  }

  function allValuesFalseOrNullEmpty(obj) {
    return Object.values(obj).every((value) => {
      if (Array.isArray(value)) {
        return value.length === 0;
      } else if (value && typeof value === 'object') {
        return allValuesFalseOrNullEmpty(value);
      } else {
        return value === false || value === null;
      }
    });
  }

  const checkWAStatusAPIcall = () => {
    let data = {
      user_id: localdata?._id,
    };
    let token = authtoken;
    EmbeddedCheckWAStatus(data, token).then((res) => {
      if (res?.flag) {
        // console.log('success response: ', res);
        setWAStatus(res?.data?.result?.verificationStatus === 'verified' ? true : false);
      } else {
        // console.log('error response: ', res);
      }
    });
  };

  const onConnect = () => {
    console.log('connected', socket.connected);
    if (socket.connected) {
      try {
        socket.emit('addPerson', localdata?._id);
      } catch (err) {
        alert("Can't connect to server");
        // the server did not acknowledge the event in the given delay
      }
    }
  };

  const loadChats = (eventdata) => {
    // console.log('data', eventdata);
    // console.log('socket', socket);
    if (searchInbox) {
      setInboxList(eventdata?.docs);
      setPage(1);
    } else {
      if (page === 1) {
        setInboxList(eventdata?.docs);
      } else {
        setInboxList((prev) => {
          const newArr = [...prev];
          for (let i = 0; i < eventdata?.docs?.length; i++) {
            let index = newArr.findIndex((item) => item._id === eventdata?.docs[i]?._id);
            if (index > -1) {
              newArr[index] = eventdata?.docs[i];
            } else {
              newArr.push(eventdata?.docs[i]);
            }
          }
          // console.log('newArr', newArr);

          return newArr;
        });
      }
    }
    setUpdateConversations(false);
    setLoadingInbox(false);
    setNext(eventdata?.hasNextPage);
    setTotalChats(eventdata?.totalUnassigned);
    setFilteredCount(eventdata?.totalDocs);
    setChatCount((count) => {
      const isAppliedFilter = (filter) => {
        return appliedFilter === filter;
      };

      return {
        ...count,
        All: isAppliedFilter('All') ? eventdata?.totalConv ?? eventdata?.totalDocs : count?.All,
        Pending: isAppliedFilter('Pending') ? eventdata?.totalDocs : -1,
        Resolved: isAppliedFilter('Resolved') ? eventdata?.totalDocs : -1,
        Unresolved: isAppliedFilter('Unresolved') ? eventdata?.totalDocs : -1,
        ChatOpen: isAppliedFilter('ChatOpen') ? eventdata?.totalDocs : -1,
        ChatClosed: isAppliedFilter('ChatClosed') ? eventdata?.totalDocs : -1,
        Unread: isAppliedFilter('Unread') ? eventdata?.totalDocs : eventdata?.totalUnread ?? -1,
        OpenandPending:isAppliedFilter('OpenandPending')? eventdata?.totalDocs : -1,
        ExpiredandPending:isAppliedFilter('ExpiredandPending') ?  eventdata?.totalDocs : -1,
      };
    });
  };

  const updateChatList = (eventdata) => {
    setMessages((prevMessages) => [...prevMessages, ...(eventdata ?? [])]);
    setLoadingMessages(false);
    // console.log('chatData  temp ', temp);
    // if (eventdata.length < 1) {
    //   chatArr = eventdata;
    //   setLoadingChatScreen(false);
    //   setChatList(eventdata);
    // }
    // // setLoading(true);
    // if (temp?.user_details?.phone == eventdata[0]?.user_phone) {
    //   setChatList(eventdata);
    //   chatArr = eventdata;
    //   setTimeout(() => {
    //     messagesEndRef.current?.scrollIntoView({ behavior: 'instant' });
    //     // setTimeout(() => {
    //     //   setLoading(false);
    //     // }, 1000);
    //   }, 1000);
    // }
    // setLoadingChatScreen(false);
  };

  const getInboxListing = useCallback(
    (pg) => {
      // console.log('getting inbox listing');
      // setLoading(true);

      /* console.log({
        id: localdata?._id,
        pgNum: pg ?? page,
        filters: filters,
      }); */

      // socket.emit('getChatListingForWeb', {
      //   id: localdata?._id,
      //   pgNum: pg ?? page,
      //   filters: filters,
      // });

      getInboxListAPIcall(page);
    },
    [page, filters]
  );

  const useDebouncedEffect = (effect, deps, delay) => {
    useEffect(() => {
      const handler = setTimeout(() => effect(), delay);

      return () => clearTimeout(handler);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...(deps || []), delay]);
  };

  useDebouncedEffect(() => getInboxListAPIcall(), [searchInbox], 500);

  const updateConversation = (eventdata) => {
    // eventdata
    //   {
    //     "agent": {
    //         "id": "65684415a581724dbce15977",
    //         "name": "Test 1"
    //     },
    //     "merchant_details": {
    //         "sender_id": "636be67d42c9a5cb33f60d98",
    //         "phone": "918008551357"
    //     },
    //     "user_details": {
    //         "name": "Priam Jain",
    //         "phone": "919779003936"
    //     },
    //     "_id": "65469dea4590afe323301c6b",
    //     "user_reply": true,
    //     "text": "Hello",
    //     "image": "",
    //     "unread_count": 1,
    //     "merchant_reply": true,
    //     "time": "2023-12-28T17:02:49.000Z",
    //     "user_profile": "https://ui-avatars.com/api/?name=Priam Jain&length=1",
    //     "balance": 18142,
    //     "stop": false,
    //     "createdAt": "2023-11-04T19:39:22.803Z",
    //     "updatedAt": "2023-12-28T17:02:50.088Z",
    //     "__v": 0,
    //     "status": "Unresolved"
    // }

    console.log('Event Data in Update', eventdata);
    // console.log('Filter applied in current update', allValuesFalseOrNullEmpty(filterRef.current));

    setIs24(eventdata);

    if (allValuesFalseOrNullEmpty(filterRef.current) && searchRef.current === '') {
      setInboxList((prev) => {
        let newArr = prev.filter((item) => !item?.blocked?.status); // Filter out blocked items
        let statusOk = false;
        let dayOk = false;
        let agentOk = false;
        let ctwaOk = false;
        let generalOk = false;
        if (filters.status.length === 0 || filters.status.includes(eventdata.status)) {
          statusOk = true;
        }
        if (!filters.day.selectedNumberOfDate || ["1", "3", "7", "30", "Select"].includes(filters.day.selectedNumberOfDate)) {
          if (filters?.day?.selectedNumberOfDate === "Select") {
            if (filters.day.startDate < eventdata.time && filters.day.endDate > new Date()) {
              dayOk = true;
            }
          } else {
            dayOk = true;
          }
        }
        if (filters.agents.length === 0 || filters.agents.includes(eventdata.agents.id)) {
          agentOk = true;
        }
        if (filters.showCtwa === false || eventdata.waReferral) {
          ctwaOk = true;
        }
        if (filters.general.length === 0 || filters.general.includes("true") || filters.general.includes("unread")) {
          generalOk = true;
        }

        if (eventdata?.blocked?.status) {
          newArr = newArr.filter((item) => item._id !== eventdata._id);
          setChatData(newArr[0]);
          const name = agentUserData?.name ?? localdata?.name; 
          const conversationId = newArr[0]?._id;
          if(conversationId){
            socket.emit('joinConversation', {conversationId, name});
          }
        } else if (statusOk && ctwaOk && generalOk && dayOk && agentOk) {
          const index = newArr.findIndex((item) => item._id === eventdata._id);
          if (index > -1) {
            newArr = newArr.filter((item) => item._id !== eventdata._id);
            if (sortOrder === -1) {
              newArr = [eventdata, ...newArr];
            }
          } else {
            if (sortOrder === -1) {
              newArr = [eventdata, ...newArr];
            }
          }
        }

        return newArr;
      });
    } else {
      let token = authtoken;
      let data = {
        page: page,
        searchQuery: searchRef.current,
        filters: filterRef.current,
        limit: 20,
        sortOrder: sortOrder ?? -1,
      };
      getInboxListingAPI(token, data).then((res) => {
        if (res?.flag) {
          loadChats(res?.data);
          // console.log('success api --------------> ', res);
        } else {
          // console.log('error api --------------> ', res);
        }
      });
    }
    // setChatData(prev => {
    //   if (prev?._id === eventdata._id) {
    //     return eventdata;
    //   }
    //   return prev;
    // })
  };

  useEffect(() => {
    if (EmbeddedOpen) {
      // console.log('embeddedOpen set to true');
      setIsEmbeddedModalOpen(true);
    }
  }, [EmbeddedOpen]);

  useEffect(() => {
    if (!isEmbeddedModalOpen) {
      setEmbeddedOpen(false);
    }
  }, [isEmbeddedModalOpen]);

  useEffect(() => {
    socket.on('newList', (eventdata) => {
      if (eventdata?.conversation_id === chatData?._id) {
        setMessages((prev) => {
          const messageIndex = prev.findIndex((message) => message._id === eventdata._id);
          if (messageIndex !== -1) {
            const updatedMessages = [...prev];
            updatedMessages[messageIndex] = eventdata;
            return updatedMessages;
          } else {
            return [eventdata, ...prev];
          }
        });
        getWorkFlowStatusApiCall();
      }
    });

    socket.on('msgStatusUpdate', (eventdata) => {
      // console.log('event data here', eventdata);
      setMessages((prevMessages) => {
        const event = eventdata;

        return prevMessages.map((message) => {
          if (message?.msg_id === event?.msg_id) {
            return event;
          }
          return message;
        });
      });
    });

    return () => {
      socket.off('newList');
      socket.off('msgStatusUpdate');
    };
  }, [chatData]);

  useEffect(() => {
    socket.on('userTyping', ({ name }) => {
      console.log(`${name} is typing...`);
      setTypingUser(`${name} is typing...`);
    });

    socket.on('userStoppedTyping', ({ name }) => {
      console.log(`${name} stopped typing.`);
      setTypingUser('');
    });

    return () => {
      socket.off('userTyping');
      socket.off('userStoppedTyping');
    };
  }, []);

  // console.log('chatData', chatData);
  const getWorkFlowStatusApiCall = () => {
    const data = {
      phoneNumberId: chatData?.user_details?.phone,
    };
    getWorkFlowStatus(data, authtoken).then((res) => {
      // console.log('res====> ', res);
      if (res?.data) {
        setActiveWorkflow(res?.data?.active);
        if (res?.data?.selfAssigned) {
          setResumeWorkflow(true);
        } else {
          setResumeWorkflow(false);
        }
      } else {
        setActiveWorkflow(false);
        setResumeWorkflow(false);
      }
    });
  };

  const handleReconnect = () => {
    console.log('Reconnecting', socket);
  };

  useEffect(() => {
    // console.log('whatsapp_replies_pageview_web');
    trackPages('whatsapp_replies_page', localdata);
    eventsend('whatsapp_replies_pageview_web');
    let param_id = getQueryParam('id');
    getInboxListAPIcall(page, param_id);
    socket.on('connect', onConnect);
    if (!firstRender.current) {
      firstRender.current = true;
      onConnect();
    }

    // Socket Connected and User Added
    socket.on('getPersons', afterConnectionSetup);
    // Get Inbox Conversations
    // socket.on('chatListingForWeb', loadChats);
    // Get Chat Messages
    // socket.on('chats', updateChatList);
    // Get New Message

    socket.on('ConversationInfo', updateConversation);
    socket.on('disconnect', () => {
      console.log(`Socket ${socketId} disconnected`);
      socket.emit('close-connection', socketId);
    });

    socket.io.on('reconnect', handleReconnect);

    // socket.on('getMassage', newMessage);
    return () => {
      socket.off('connect', onConnect);
      socket.off('getPersons', afterConnectionSetup);
      // socket.off('chatListingForWeb', loadChats);
      // socket.off('chats', updateChatList);
      // socket.off('getMassage', updateMessageList);
      socket.off('disconnect', () => {
        console.log(`Socket ${socketId} disconnected`);
        socket.emit('close-connection', socketId);
      });
      socket.io.off('reconnect', handleReconnect);

      socket.off('ConversationInfo', updateConversation);
    };
  }, [socket.connected]);

  const getInboxListAPIcall = (page = 1, selectedId = null) => {
    let token = authtoken;
    let data = {
      page: page,
      searchQuery: searchInbox,
      filters: filters,
      limit: 20,
      selectedId: selectedId ?? '',
      sortOrder: sortOrder ?? -1,
    };
    getInboxListingAPI(token, data).then((res) => {
      if (res?.flag) {
        loadChats(res?.data);
        // console.log('success api --------------> ', res);
      } else {
        // console.log('error api --------------> ', res);
      }
    });
  };

  const getConversationsByIDAPIcall = (id, page, limit) => {
    let token = authtoken;
    let data = {
      _id: id,
      page: page,
      limit: limit,
    };
    // console.log("DATA => ", data)
    getConversationByIDAPI(token, data).then((res) => {
      if (res?.flag) {
        updateChatList(res?.data);
        if (res?.data?.length < limit) {
          setMore(false);
        }
        // console.log('success api --------------> ', res);
      } else {
        // console.log('error api --------------> ', res);
      }
    });
  };

  /* useEffect(() => {
    console.log('chatData', chatData);
    if (chatData) {
      console.log('getting messages');
      setLoadingMessages(true);
      getConversationsByIDAPIcall(chatData?._id);
      // socket.emit('getChats', {
      //   id: chatData?.merchant_details?.sender_id,
      //   phone: chatData?.user_details?.phone,
      //   receiver_id: chatData?.merchant_details?.sender_id,
      // });
    }
  }, [chatData]); */

  const fetchMessages = (page, limit) => {
    getConversationsByIDAPIcall(chatData?._id, page, limit);
  };

  useEffect(() => {
    // console.log('chatData', chatData);
    if (chatData) {
      // console.log('getting messages');
      setLoadingMessages(true);
      setMessages([]);
      setMore(true);
      setMessagePage(1); // Reset page to 1 when chatData changes
      fetchMessages(1, limit); // Fetch messages for the first page
      getWorkFlowStatusApiCall();
    }
  }, [chatData?._id]);

  const loadMore = () => {
    setMessagePage(messagePage + 1); // Increment page number
    fetchMessages(messagePage + 1, limit); // Fetch messages for the next page
  };

  useEffect(() => {
    // console.log('getting updateConversations', updateConversations);
    if (connection && updateConversations) {
      // console.log('getting page', page);
      if (page == 1) {
        setLoadingInbox(false);
      }
      // getInboxListing();
      getInboxListAPIcall(page);
    }
  }, [updateConversations]);

  const GetAgentsV2APICall = () => {
    let token = authtoken;
    V2AgentGet(token).then((res) => {
      if (res) {
        setAgentData(res?.data);
      } else {
      }
    });
  };
  useEffect(() => {
    GetAgentsV2APICall();
  }, []);

  const mobileChangeScreenAction = (e) => {
    // setTimeout(() => {
    setShowScreen(e);
    // }, 500);
  };

  // console.log('inbox', inboxList);
  return (
    <>
      {!isFTUser && !isPremiumUser && (
        <PremiumOptionModal
          option={'WhatsApp Inbox'}
          openPremiumModal={true}
          setOpenPremiumModal={() => { }}
        ></PremiumOptionModal>
      )}
      {!isPremiumUser ? (
        <div style={styles.whatsapp_replies.container}>
          <div
            style={{
              ...styles.whatsapp_replies.waInbox,
              width: MobileWidth ? '100%' : '30%',
            }}
          >
            <div style={{}}>
              <div
                className="d-flex flex-row "
                style={{
                  padding: '8px',
                  position: 'relative',
                }}
              >
                <div style={{ width: '90%' }}>
                  <h3
                    style={{
                      color: colors.black,
                      padding: '10px',
                      fontWeight: '700',
                      fontSize: '24px',
                    }}
                  >
                    {'Whatsapp'}
                  </h3>
                </div>
                <div style={{ width: '10%', marginTop: '3%' }}>
                  <img src={images.ThreeDot} style={{ height: '24px', width: '24px' }} />
                </div>
              </div>
            </div>
            <div
              style={{
                backgroundColor: colors.white,
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                height: '100%',
              }}
            >
              <div style={{ backgroundColor: colors.white }}>
                <div
                  style={{
                    display: 'flex',
                    backgroundColor: colors.white,
                    flexDirection: 'column',
                    padding: '12px',
                    marginBottom: '10px',
                  }}
                >
                  <InputGroup className="my-2">
                    <InputGroup.Text
                      style={{
                        borderRightWidth: 0,
                        backgroundColor: 'transparent',
                        borderColor: '#B3B3B3',
                        paddingInline: 8,
                      }}
                    >
                      <IoIosSearch size={16} />
                    </InputGroup.Text>
                    <Form.Control
                      type="text"
                      placeholder="Search here"
                      style={{
                        borderTopRightRadius: searchInbox ? '0' : 8,
                        borderBottomRightRadius: searchInbox ? '0' : 8,
                        borderColor: '#B3B3B3',
                        borderLeftWidth: 0,
                        paddingInlineStart: 0,
                        zIndex: 1,
                        outline: 'none',
                        boxShadow: 'none',
                        borderRightWidth: searchInbox ? '0' : '',
                      }}
                      onFocus={(e) => {
                        e.target.style.outline = 'none';
                        e.target.style.borderColor = '#B3B3B3';
                      }}
                      value={searchInbox}
                      onChange={(e) => {
                        setSearchInbox(e.target.value);
                      }}
                    />
                    {searchInbox && (
                      <InputGroup.Text
                        onClick={() => {
                          setSearchInbox('');
                        }}
                        style={{
                          borderLeftWidth: 0,
                          backgroundColor: 'transparent',
                          borderColor: '#B3B3B3',
                          paddingInline: 8,
                          cursor: 'pointer',
                        }}
                      >
                        <RxCross1 size={16} />
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              ...styles.whatsapp_replies.waInbox,
              width: MobileWidth ? '0%' : '70%',
            }}
          >
            {PRWAModalOpen && (
              <PreReqWASetup
                setIsModalOpen={setPRWAModalOpen}
                proceedToSetup={() => {
                  setIsWAPresetupModalOpen(true);
                  setPRWAModalOpen(false);
                }}
              />
            )}
            {isWAPresetupModalOpen && (
              <WAPreSetupModal
                MetaEmbedded={false}
                // MetaEmbedded={MetaEmbeddedUser}
                AISensyEmbedded={true}
                // AISensyEmbedded={
                //   (Helper.EmbeddedAI == true ||
                //     localdata?.is_v2_user == true ||
                //     localdata?.ai_sensy_whatsapp_config == true) &&
                //   !MetaEmbeddedUser
                // }
                setIsWAPreSetupOpen={setIsWAPresetupModalOpen}
                onclickAction={() => {
                  window.FB.login(
                    function (response) {
                      console.log({ response });
                      if (response.authResponse) {
                        const code = response.authResponse.accessToken;
                        // console.log("CODE CODE CODE => ", code);
                        dispatch({ type: 'tempFBToken', payload: code });
                        // window.launchWhatsAppSignup();
                        setIsEmbeddedModalOpen(true);
                        // The returned code must be transmitted to your backend,
                        // which will perform a server-to-server call from there to our servers for an access token
                      } else {
                        console.log('User cancelled login or did not fully authorize.');
                      }
                    },
                    {
                      scope: 'whatsapp_business_management,business_management,catalog_management',
                      // response_type: 'code', // must be set to 'code' for System User access token
                      // override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
                      // sessionInfoVersion: 2, //  Receive Session Logging Info
                      extras: {
                        feature: 'whatsapp_embedded_signup',
                        setup: {
                          solutionID: '883160333502568',
                        },
                      },
                    }
                  );
                }}
                onClickApply={() => {
                  setIsEmbeddedModalOpen(true);
                }}
                openEmbedded={() => {
                  console.log('setEmbeddedOpen to true');
                  setEmbeddedOpen(true);
                }}
                WAStatus={WAStatus}
              />
            )}
            {isEmbeddedModalOpen && (
              <EmbeddedModal
                setIsEmbeddedModalOpen={setIsEmbeddedModalOpen}
                openEmbeddedAction={EmbeddedOpen}
                checkWAStatusAction={() => {
                  checkWAStatusAPIcall();
                }}
              />
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '2%' }}>
              <CheerioButton
                borderStyle={{ borderColor: colors.black, width: '25%' }}
                textStyle={{ color: colors.white01, fontWeight: 600 }}
                btnText={'Setup WhatsApp Inbox'}
                backColor={colors.black}
                onclick={() => {
                  setPRWAModalOpen(true);
                }}
              />
            </div>
            <div style={{ marginTop: '-2%', marginLeft: '2%' }}>
              <img src={images.EmptyInbox} style={{ height: '200px' }} />
              <div style={{ padding: '10px' }}>
                <h3 style={{ fontSize: '24px', fontWeight: '700', paddingBottom: '10px' }}>
                  Nothing to see yet!{' '}
                </h3>
                <p style={{ color: colors.greys01 }}>
                  Setup your WhatsApp Inbox to start receiving your queries here
                </p>
                <div className="d-flex flex-row pt-2">
                  <CheerioButton
                    borderStyle={{ borderColor: colors.black, width: '25%' }}
                    textStyle={{ color: colors.white01, fontWeight: 600 }}
                    btnText={'Setup WhatsApp Inbox'}
                    backColor={colors.black}
                    onclick={() => {
                      setPRWAModalOpen(true);
                    }}
                  />
                </div>
                <div style={{ marginTop: '3%' }}>
                  <h3 style={{ paddingBottom: '6px' }}>Features with in Chat Inbox</h3>
                  <p>1. Send & receive messages from your customers on WhatsApp directly </p>
                  <p>2. Distribute chat/ customer queries among agents </p>
                  <p>3. Start a new chat with a customers </p>
                  <p>
                    4. Save unlimited canned responses (including text, media, documents, links,
                    buttons){' '}
                  </p>
                  <p>5. Add/update custom contact attributes for a contact globally </p>
                  <p>6. Send template messages </p>
                  <p>7. Track 24 hour conversation window </p>
                  <p>8. Add comment/notes for your reference and other team members</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <div className="spinner_loader">
              <SpinnerLoader />
            </div>
          ) : (
            <div
              style={{
                ...styles.whatsapp_replies.container,
                overflow: 'hidden',
                width: '100%',
                height: '100vh',
              }}
            >
              <div
                style={{
                  ...styles.whatsapp_replies.waInbox,
                  width:
                    MobileWidth && showScreen === 'inbox' ? '100%' : MobileWidth ? '0' : paneWidth,
                  position: 'relative',
                }}
              >
                {loadingInbox ? (
                  <div className="spinner_loader">
                    <SpinnerLoader />
                  </div>
                ) : (
                  <WAInbox
                    chatCount={chatCount}
                    excepted={excepted}
                    paneWidth={paneWidth}
                    setAppliedFilter={setAppliedFilter}
                    filteredCount={filteredCount}
                    isChatLoad={isChatLoad}
                    list={inboxList}
                    // setSelectedIndex={setSelectedIndex}
                    selectedItem={chatData}
                    setSelectedItem={(data) => {
                      updateQueryParam('id', data?._id);
                      setChatData(data);
                      const name = agentUserData?.name ?? localdata?.name; 
                      const conversationId = data?._id;
                      if(conversationId){
                        socket.emit('joinConversation', {conversationId, name});
                      }
                      setInboxList((prev) => {
                        const newArr = [...prev];
                        const index = newArr.findIndex((item) => item._id === data._id);
                        if (index > -1) {
                          newArr[index] = { ...data, unread_count: 0 };
                        }
                        return newArr;
                      });
                    }}
                    waData={localdata?.whatsapp_credentials}
                    profileImg={localdata?.picture}
                    page={page}
                    setPage={(pg) => {
                      setPage(pg);
                      setUpdateConversations(true);
                    }}
                    hasNext={next}
                    filters={filters}
                    setFilters={setFilters}
                    setInboxList={setInboxList}
                    getInboxListing={getInboxListing}
                    agentData={agentData}
                    setAgentData={setAgentData}
                    totalChats={totalChats}
                    mobileChangeScreen={mobileChangeScreenAction}
                    chatData={chatData}
                    setChatData={setChatData}
                    searchInbox={searchInbox}
                    setSearchInbox={setSearchInbox}
                    removeFilterAction={() => {
                      setFilters({
                        general: [],
                        status: [],
                        day: {
                          selectedNumberOfDate: null,
                          startDate: null,
                          endDate: null,
                        },
                        agents: agentUserData?.role === 'agent' ? [agentUserData?._id] : [],
                        showCtwa: false,
                      });
                    }}
                    sortOrder={sortOrder}
                    setSortOrder={setSortOrder}
                    activeWorkflow={activeWorkflow}
                  />
                )}
              </div>

              {/* {!MobileWidth && (
                <div
                  style={{
                    height: '100%',
                    width: '3px',
                    cursor: 'ew-resize',
                    backgroundColor: '#CCCCCC',
                  }}
                  onMouseDown={handleMouseDown}
                >
                  <img
                    src={images.WASlider}
                    style={{
                      marginTop: '45vh',
                      height: 'auto',
                      width: '10px',
                      backgroundColor: '#CCCCCC',
                      zIndex: 2,
                      marginLeft: '-3px',
                      borderRadius: '8px',
                      position: 'absolute',
                    }}
                  ></img>
                </div>
              )} */}
              {loadingMessages ? (
                <div className="spinner_loader" style={{ height: '100%', width: '100%' }}>
                  <SpinnerLoader />
                </div>
              ) : (
                !loadingMessages && (
                  <div
                    style={{
                      ...styles.whatsapp_replies.waInbox,
                      width:
                        MobileWidth && showScreen === 'chatview'
                          ? '100%'
                          : MobileWidth
                            ? '0%'
                            : window.innerWidth - paneWidth,
                      position: 'relative',
                    }}
                  >
                    <WAChatView
                      chatData={is24?._id === chatData?._id ? is24 : chatData}
                      setChatData={setChatData}
                      activeWorkflow={activeWorkflow}
                      excepted={excepted}
                      setActiveWorkflow={setActiveWorkflow}
                      resumeWorkflow={resumeWorkflow}
                      setResumeWorkflow={setResumeWorkflow}
                      isChatLoad={isChatLoad}
                      updateNewMessage={setUpdateMsg}
                      agentData={agentData}
                      setAgentData={setAgentData}
                      setInboxList={setInboxList}
                      getInboxListing={getInboxListing}
                      mobileChangeScreen={mobileChangeScreenAction}
                      setLoadingChatScreen={setLoadingChatScreen}
                      loadingChatScreen={loadingChatScreen}
                      socket={socket}
                      loadMore={loadMore}
                      typingUser={typingUser}
                      more={more}
                      messages={messages}
                      currentUser={agentUserData?.name ?? localdata?.name}
                      // setChatStatus={(val) => {
                      //   // setChatData((prev) => {
                      //   //   return { ...prev, status: val };
                      //   // });
                      //   setInboxList((prev) => {
                      //     const newArr = [...prev];
                      //     const index = newArr.findIndex((item) => item._id === chatData._id);
                      //     if (index > -1) {
                      //       newArr[index] = { ...chatData, status: val };
                      //     }
                      //     return newArr;
                      //   });
                      // }}
                      setAgent={(agent) => {
                        setInboxList((prev) => {
                          const newArr = [...prev];
                          const index = newArr.findIndex((item) => item._id === chatData._id);
                          if (index > -1) {
                            newArr[index] = { ...chatData, agent: agent };
                          }
                          return newArr;
                        });
                      }}
                    />
                  </div>
                )
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = {};

// export default connect(mapStateToProps, mapDispatchToProps)(WhatsAppReplies);

export default WhatsAppReplies;

const styles = {
  whatsapp_replies: {
    container: {
      display: 'flex',
      width: '100%',
      border: '1px solid #E6E6E6',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderRadius: 10,
      height: '100%',
    },
    waInbox: {
      borderRight: '1px solid #E6E6E6',
      backgroundColor: colors.white,
    },
  },
};
